import React from "react";
// import { useNavigate } from "react-router-dom";

const UpgradeModal = ({ model, onUpgrade, onClose }) => {
  //   const navigate = useNavigate();

  //   const handleUpgrade = () => {
  //     onClose();
  //     navigate("/myplan");
  //   };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg max-w-sm w-full">
        <h2 className="text-xl font-bold mb-4">Upgrade Your Plan</h2>
        <p>
          You need to upgrade your plan to access the <strong>{model}</strong>{" "}
          model.
        </p>
        <div className="mt-6 flex justify-end">
          <button
            onClick={onUpgrade}
            className="bg-blue-500 text-white px-4 py-2 rounded mr-2 hover:bg-blue-600"
          >
            Upgrade Now
          </button>
          <button
            onClick={onClose}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpgradeModal;
