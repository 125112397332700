import React from "react";
import { useNavigate } from "react-router-dom";

const ChatDataHandler = ({
  fetchWithAuth,
  serverUrl,
  setSavedChats,
  setError,
  setChatHistory,
  setChatId,
  setChatNames,
}) => {
  const navigate = useNavigate();
  const cleanString = (str) => {
    if (typeof str === "string") {
      return str.replace(/^["'](.+(?=["']$))["']$/, "$1");
    }
    return str;
  };

  const fetchSavedChats = async () => {
    try {
      const response = await fetchWithAuth(`${serverUrl}/api/get-saved-chats`);
      if (response.status === 403) {
        console.error("Authentication error: User is not authorized");
        setError(
          "You are not authorized to view saved chats. Please log in again."
        );
        navigate("/login");
        return;
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      if (!data || !Array.isArray(data.chats)) {
        console.error("Unexpected response structure:", data);
        throw new Error("No data in response");
      }

      const processedChats = data.chats.map((chat) => ({
        ...chat,
        chatName: chat.chatName || `Chat ${chat.id}`,
        createdAt: new Date(chat.createdAt),
      }));

      setSavedChats(processedChats);

      // Update chat names in the parent component
      const chatNamesObject = processedChats.reduce((acc, chat) => {
        acc[chat.chatId] = chat.chatName;
        return acc;
      }, {});
      setChatNames(chatNamesObject);
    } catch (error) {
      console.error("Error fetching saved chats:", error);
      setSavedChats([]);
      setChatNames({});
    }
  };

  const loadChat = async (id) => {
    console.log("I am in loadChat");

    try {
      const response = await fetchWithAuth(`${serverUrl}/api/get-chat/${id}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (!data || !Array.isArray(data.chat)) {
        console.error("Unexpected chat data structure:", data);
        throw new Error("Unexpected chat data structure");
      }
      setChatHistory(data.chat);
      setChatId(id);
      if (data.chatName) {
        setChatNames((prevNames) => ({
          ...prevNames,
          [id]: cleanString(data.chatName),
        }));
      }
    } catch (error) {
      console.error("Error loading chat:", error);
      setError("Failed to load chat");
    }
  };

  return { fetchSavedChats, loadChat };
};

export default ChatDataHandler;
