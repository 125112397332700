import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenFromUrl = queryParams.get("token");
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
    } else {
      alert("Invalid or missing token.");
      navigate("/login");
    }
  }, [location.search, navigate]);

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      alert("Passwords do not match.");
      return;
    }

    if (newPassword.length < 6) {
      alert("Password should be at least 6 characters long.");
      return;
    }

    try {
      const response = await axios.post(`${serverUrl}/api/reset-password`, {
        token,
        newPassword,
      });

      alert(response.data.message);
      navigate("/login", {
        state: {
          verificationMessage: "Password reset successful. Please log in.",
        },
      });
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while resetting the password.";
      alert(errorMessage);
    }
  };

  return (
    <div className="flex justify-center items-center bg-blue-100 h-screen">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-sm">
        <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">
          Reset Password
        </h2>
        <form onSubmit={handleResetPassword} className="space-y-4">
          <div>
            <label>New Password:</label>
            <input
              type="password"
              className="border-2 border-gray-300 p-3 w-full rounded-md focus:border-purple-500"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              placeholder="Enter new password"
            />
          </div>
          <div>
            <label>Confirm New Password:</label>
            <input
              type="password"
              className="border-2 border-gray-300 p-3 w-full rounded-md focus:border-purple-500"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              placeholder="Re-enter new password"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-purple-600 text-white p-3 rounded-md hover:bg-purple-700 focus:outline-none"
          >
            Reset Password
          </button>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
