import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App.jsx";
import "./index.css";
import axios from "axios";

const originalFetch = window.fetch;

// Override fetch with a version that includes credentials
window.fetch = (url, options = {}) => {
  return originalFetch(url, {
    ...options,
    credentials: "include",
  });
};

// Global configuration for axios
axios.defaults.withCredentials = true;

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
