import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import ThreeDotsWave from "./MainPageComponents/ThreeDotsWave.js";
import useFetchWithAuth from "./MainPageComponents/useFetchWithAuth.js";
import SubmitHandler from "./MainPageComponents/SubmitHandler.js";
import ChatDataHandler from "./MainPageComponents/ChatDataHandler.jsx";
import MyPlan from "./MyPlan.jsx";
import { useTheme } from "./ThemeContext";
import Settings from "./Settings";
import ChatHistoryDisplay from "./MainPageComponents/ChatHistoryDisplay.js";
import HeaderBar from "./MainPageComponents/HeaderBar.jsx";
import Sidebar from "./MainPageComponents/Sidebar.jsx";
import UsageLimitExceededModal from "./UsageLimitExceededModal";
import UsageWarningModal from "./MainPageComponents/UsageWarningModal.jsx";
import UpgradeModal from "./MainPageComponents/UpgradeModal.jsx";

function SimpleChatDisplay() {
  const [showUsageLimitModal, setShowUsageLimitModal] = useState(false);
  const [showUsageWarningModal, setShowUsageWarningModal] = useState(false);
  const [hasShownUsageWarning, setHasShownUsageWarning] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [desiredModel, setDesiredModel] = useState(null);
  const [isModelDropdownOpen, setIsModelDropdownOpen] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [savedChats, setSavedChats] = useState([]);
  const [question, setQuestion] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [error, setError] = useState(null);
  // const [newAnswer, setNewAnswer] = useState("");

  const location = useLocation();
  const initialEmail =
    location.state?.email || localStorage.getItem("userEmail");
  const [userEmail, setUserEmail] = useState(initialEmail || "");
  const [chatId, setChatId] = useState(null);
  const [iconTypes, setIconTypes] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [chatNames, setChatNames] = useState({});
  const [selectedAPI, setSelectedAPI] = useState("GPT 4o mini");
  const [gptVersion, setGptVersion] = useState("GPT 4o mini");
  const [isForbidden, setIsForbidden] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [editedQuestion, setEditedQuestion] = useState("");
  const [totalUsageAmount, setTotalUsageAmount] = useState(0);
  const [usageCap, setUsageCap] = useState(0);
  const [userPlanType, setUserPlanType] = useState("");

  const { theme } = useTheme();
  axios.defaults.withCredentials = true;
  const navigate = useNavigate();
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  // const serverUrl = `${config.serverUrl}`;
  const gptBarRef = useRef(null);
  const submitHandlerRef = useRef(null);
  const fetchWithAuth = useFetchWithAuth(serverUrl);
  const { fetchSavedChats, loadChat } = ChatDataHandler({
    fetchWithAuth,
    serverUrl,
    setSavedChats,
    setError,
    setChatHistory,
    setChatId,
    setChatNames,
  });
  const fetchUsageData = useCallback(async () => {
    try {
      const response = await fetchWithAuth(`${serverUrl}/api/get-usage`);
      if (response.ok) {
        const data = await response.json();
        setUsageCap(data.usageCap);
        setTotalUsageAmount(data.totalUsageAmount);
      } else {
        console.error("Failed to fetch usage data");
      }
    } catch (error) {
      console.error("Error fetching usage data:", error);
    }
  }, [fetchWithAuth, serverUrl]);

  useEffect(() => {
    fetchSavedChats();
  }, []);

  useEffect(() => {
    fetchUsageData();
  }, [fetchUsageData]);

  useEffect(() => {
    console.log("Usage Cap:", usageCap);
    console.log("Total Usage Amount:", totalUsageAmount);
    if (usageCap > 0) {
      const usagePercentage = (totalUsageAmount / usageCap) * 100;
      console.log("Usage Percentage:", usagePercentage);
      if (
        usagePercentage >= 90 &&
        usagePercentage < 100 &&
        !hasShownUsageWarning
      ) {
        setShowUsageWarningModal(true);
        setHasShownUsageWarning(true);
      }
      if (usagePercentage >= 100) {
        setShowUsageLimitModal(true);
      }
    }
  }, [totalUsageAmount, usageCap, hasShownUsageWarning]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (gptBarRef.current && !gptBarRef.current.contains(event.target)) {
        setIsModelDropdownOpen(false);
      }
    };
    if (setIsModelDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsModelDropdownOpen]);

  useEffect(() => {
    // Clear session ID when component unmounts (chat ends)
    return () => {
      localStorage.removeItem("sessionId");
    };
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetchWithAuth(`${serverUrl}/api/user-data`);
        if (response.ok) {
          const data = await response.json();
          console.log("Fetched user data:", data);

          setUserEmail(data.email);
          setUserPlanType(data.planType); // Set user plan type
        } else {
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserData();
  }, [fetchWithAuth, serverUrl]);

  const setChatName = (chatId, name) => {
    setChatNames((prev) => ({ ...prev, [chatId]: name }));
  };

  const startNewChat = () => {
    setChatId(null);
    setChatHistory([]);
    setQuestion("");
    setSelectedFiles([]);
    window.location.reload();
  };

  useEffect(() => {
    setIconTypes(
      chatHistory.map((chat) =>
        chat.answer
          .split(/(```[\s\S]*?```)/)
          .filter((part) => part.startsWith("```") && part.endsWith("```"))
          .map(() => "copy")
      )
    );
  }, [chatHistory]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetchWithAuth(`${serverUrl}/api/user-data`);
        if (response.ok) {
          const data = await response.json();
          setUserEmail(data.email);
        } else {
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserData();
  }, [fetchWithAuth, serverUrl]);

  useEffect(() => {
    if (userEmail) {
      localStorage.setItem("userEmail", userEmail);
    }
  }, [userEmail]);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await fetchWithAuth(`${serverUrl}/api/check-session`);
        if (response.ok) {
          const data = await response.json();
          if (data.isAuthenticated) {
            setPageLoading(false);
          } else {
            setPageLoading(false);
            throw new Error("Session check failed");
          }
        } else {
          setPageLoading(false);
          throw new Error("Session not active");
        }
      } catch (error) {
        setPageLoading(false);
        console.error("Error fetching session:", error);
        if (error.response && error.response.status === 401) {
          navigate("/login");
        } else if (error.response && error.response.status === 403) {
          setIsForbidden(true);
        } else {
          console.error("Error fetching session:", error.message);
        }
      }
    };
    checkSession();
  }, [navigate, fetchWithAuth, serverUrl]);

  useEffect(() => {
    const fetchMainPageData = async () => {
      try {
        const response = await fetchWithAuth(`${serverUrl}/mainpage`);
        if (!response.ok) throw new Error("Failed to fetch main page data");
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/login");
        } else if (error.response && error.response.status === 403) {
          setIsForbidden(true);
        }
      }
    };
    fetchMainPageData();
  }, [navigate, fetchWithAuth, serverUrl]);

  useEffect(() => {
    const interval = setInterval(async () => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        const { exp } = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (exp - currentTime < 300) {
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_SERVER_URL}/api/refresh-token`,
              {},
              {
                withCredentials: true,
              }
            );
            if (response.data.accessToken) {
              localStorage.setItem("accessToken", response.data.accessToken);
            }
          } catch (error) {
            console.error("Error refreshing token:", error);
            // Handle error (e.g., redirect to login page)
          }
        }
      }
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/test-cookies`, {
        withCredentials: true,
      })
      .then((response) => console.log("Cookies:", response.data))
      .catch((error) => console.error("Error:", error));
  }, []);

  // const handleChatResponse = useCallback(
  //   (event) => {
  //     const data = JSON.parse(event.data);
  //     if (data.content) {
  //       setNewAnswer((prev) => prev + data.content);
  //     }
  //     if (data.type === "DONE") {
  //       // Finalize the answer
  //       setChatHistory((prev) =>
  //         prev.map((chat, index) =>
  //           index === prev.length - 1 ? { ...chat, answer: newAnswer } : chat
  //         )
  //       );
  //       setNewAnswer("");
  //     }
  //     if (data.error) {
  //       // Handle error
  //       console.error(data.error);
  //     }
  //   },
  //   [newAnswer]
  // );

  // useEffect(() => {
  //   const eventSource = new EventSource(`${serverUrl}/api/chat-stream`);

  //   eventSource.onmessage = handleChatResponse;

  //   eventSource.onerror = (err) => {
  //     console.error("EventSource failed:", err);
  //     eventSource.close();
  //   };

  //   return () => {
  //     eventSource.close();
  //   };
  // }, [serverUrl, handleChatResponse]);

  const getFileIcon = (fileType) => {
    if (fileType.startsWith("image/")) return "🖼️";
    if (fileType.includes("pdf")) return "📄";
    if (fileType.includes("word")) return "📝";
    if (fileType.includes("excel") || fileType.includes("spreadsheet"))
      return "📊";
    return "📎";
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleModelDropdown = () => {
    setIsModelDropdownOpen(!isModelDropdownOpen);
  };

  const handleDeleteChat = useCallback(
    async (index) => {
      try {
        await fetchWithAuth(`${serverUrl}/api/delete-chat-entry`, {
          method: "DELETE",
          body: JSON.stringify({ chatId, index }),
        });
        setChatHistory((prevHistory) =>
          prevHistory.filter((_, i) => i !== index)
        );
      } catch (error) {
        console.error("Error deleting chat entry:", error);
        setError("Failed to delete chat entry");
      }
    },
    [chatId, fetchWithAuth, serverUrl]
  );

  const handleEditChat = useCallback(
    async (index, newQuestion, modelVersion) => {
      setEditIndex(index);
      setEditedQuestion(newQuestion);
    },
    [setEditIndex, setEditedQuestion]
  );

  const handleModelChange = async (model) => {
    console.log("Attempting to change model to:", model);

    const accessibleModels = [
      "GPT 4o mini",
      "Claude 3 haiku",
      "Gemini 1.5 Flash",
    ];
    const isAccessible = accessibleModels.includes(model);

    if (!isAccessible && userPlanType === "free") {
      setDesiredModel(model);
      setShowUpgradeModal(true);
      return;
    }
    try {
      const response = await fetchWithAuth(
        `${serverUrl}/api/check-access/${model}`
      );
      if (response.status === 403) {
        setDesiredModel(model);
        setShowUpgradeModal(true);
      } else if (response.ok) {
        setGptVersion(model);
        setSelectedAPI(model);
        setIsModelDropdownOpen(false);
      } else {
        console.error("Unexpected error checking model access");
      }
    } catch (error) {
      console.error("Error checking model access:", error);
    }
  };

  const handleUsageUpgradePlan = () => {
    setShowUsageLimitModal(false);
    setShowUsageWarningModal(false);
    setHasShownUsageWarning(false);
    navigate("/myplan");
  };

  const handleModelUpgradePlan = () => {
    setShowUpgradeModal(false);
    navigate("/myplan");
  };

  const handleOpenSettingsModal = () => setShowSettingsModal(true);

  const handleQuestionDisplayed = useCallback(() => {
    setQuestion("");
    setSelectedFiles([]);
  }, []);

  if (pageLoading) {
    return (
      <div
        className={`flex items-center justify-center h-screen ${
          theme === "dark" ? "bg-gray-900 text-gray-200" : "bg-white text-black"
        }`}
      >
        <ThreeDotsWave />
      </div>
    );
  }
  if (isForbidden) {
    return (
      // <div className="flex items-center justify-center h-screen">
      <div
        className={`flex items-center justify-center h-screen ${
          theme === "dark" ? "bg-gray-900 text-gray-200" : "bg-white text-black"
        }`}
      >
        <h1 className="text-red-500 text-2xl">Access Forbidden</h1>
      </div>
    );
  } else {
    return (
      <>
        <div
          className={`flex h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 ${theme}`}
        >
          <Sidebar
            error={error}
            savedChats={savedChats}
            chatNames={chatNames}
            loadChat={loadChat}
            fetchSavedChats={fetchSavedChats}
            serverUrl={serverUrl}
            userEmail={userEmail}
            fetchWithAuth={fetchWithAuth}
            theme={theme}
            sidebarOpen={sidebarOpen}
            handleOpenSettingsModal={handleOpenSettingsModal}
          />
          <div
            className={`flex flex-col flex-grow transition-margin duration-300 ease-in-out ${
              sidebarOpen ? "ml-[250px]" : "ml-0"
            }`}
          >
            <HeaderBar
              theme={theme}
              toggleSidebar={toggleSidebar}
              sidebarOpen={sidebarOpen}
              startNewChat={startNewChat}
              toggleModelDropdown={toggleModelDropdown}
              isModelDropdownOpen={isModelDropdownOpen}
              gptBarRef={gptBarRef}
              selectedAPI={selectedAPI}
              handleModelChange={handleModelChange}
              userPlanType={userPlanType}
            />
            <main className="flex-1 overflow-y-auto w-full max-w-4xl mx-auto mt-5 mb-[110px] px-4">
              <div className="space-y-4">
                <ChatHistoryDisplay
                  chatHistory={chatHistory}
                  handleDeleteChat={handleDeleteChat}
                  handleEditChat={handleEditChat}
                  editIndex={editIndex}
                  editedQuestion={editedQuestion}
                  setEditedQuestion={setEditedQuestion}
                  submitHandlerRef={submitHandlerRef}
                  setEditIndex={setEditIndex}
                  handleQuestionDisplayed={handleQuestionDisplayed}
                  getFileIcon={getFileIcon}
                />
              </div>
            </main>
            <div className="fixed bottom-4 left-0 right-0 flex justify-center px-4">
              <div
                className={`w-full max-w-4xl transition-all duration-300 ${
                  sidebarOpen ? "ml-[250px]" : "ml-0"
                }`}
              >
                <SubmitHandler
                  ref={submitHandlerRef}
                  question={question}
                  setQuestion={setQuestion}
                  gptVersion={gptVersion}
                  chatId={chatId}
                  setChatId={setChatId}
                  setChatName={setChatName}
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  setChatHistory={setChatHistory}
                  serverUrl={serverUrl}
                  fetchWithAuth={fetchWithAuth}
                  setIsLoading={setIsLoading}
                  setError={setError}
                  setIsUsageLimitExceeded={setShowUsageLimitModal}
                  isLoading={isLoading}
                  FileList={FileList}
                  setIsForbidden={setIsForbidden}
                  navigate={navigate}
                  onQuestionDisplayed={handleQuestionDisplayed}
                  // themeClasses={`bg-light dark:bg-dark text-light dark:text-dark ${theme}`}
                  themeClasses="bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100"
                  sessionId={sessionId}
                  setSessionId={setSessionId}
                  editIndex={editIndex}
                  setEditIndex={setEditIndex}
                  fetchUsageData={fetchUsageData}
                />
              </div>
            </div>
          </div>
        </div>
        {showSettingsModal && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
            <Settings
              setShowSettingsModal={setShowSettingsModal}
              userEmail={userEmail}
              serverUrl={serverUrl}
              fetchWithAuth={fetchWithAuth}
            />
          </div>
        )}
        {showUsageLimitModal && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
            <UsageLimitExceededModal
              setShowUsageLimitModal={setShowUsageLimitModal}
              onUpgrade={handleUsageUpgradePlan}
            />
          </div>
        )}
        {showUsageWarningModal && (
          <UsageWarningModal
            setShowUsageWarning={setShowUsageWarningModal}
            onUpgrade={handleUsageUpgradePlan}
          />
        )}
        {showUpgradeModal && (
          <UpgradeModal
            model={desiredModel}
            onUpgrade={handleModelUpgradePlan}
            onClose={() => setShowUpgradeModal(false)}
          />
        )}
      </>
    );
  }
}

export default SimpleChatDisplay;
