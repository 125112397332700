import React, { useEffect, useState } from "react";
import { useTheme } from "./ThemeContext";

const ThemeSwitcher = () => {
  const { theme, setTheme } = useTheme();
  const [systemTheme, setSystemTheme] = useState("light");

  useEffect(() => {
    // Check system preference
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    setSystemTheme(mediaQuery.matches ? "dark" : "light");

    const handler = () => setSystemTheme(mediaQuery.matches ? "dark" : "light");
    mediaQuery.addListener(handler);
    return () => mediaQuery.removeListener(handler);
  }, []);

  const handleThemeChange = (newTheme) => {
    setTheme(newTheme);
    if (newTheme !== "system") {
      localStorage.setItem("theme", newTheme);
    } else {
      localStorage.removeItem("theme");
    }
  };

  return (
    <div className="mb-4">
      <h2 className="text-lg font-semibold mb-2">Appearance</h2>
      <div className="space-y-2">
        <label className="flex items-center">
          <input
            type="radio"
            value="light"
            checked={theme === "light"}
            onChange={() => handleThemeChange("light")}
            className="mr-2"
          />
          Light theme
        </label>
        <label className="flex items-center">
          <input
            type="radio"
            value="dark"
            checked={theme === "dark"}
            onChange={() => handleThemeChange("dark")}
            className="mr-2"
          />
          Dark theme
        </label>
        <label className="flex items-center">
          <input
            type="radio"
            value="system"
            checked={theme === "system"}
            onChange={() => handleThemeChange("system")}
            className="mr-2"
          />
          System (automatic)
        </label>
      </div>
    </div>
  );
};

export default ThemeSwitcher;
