import React from "react";
import { useNavigate } from "react-router-dom";
import { BsHouse } from "react-icons/bs";
import { useTheme } from "./ThemeContext";

function About() {
  const { theme } = useTheme();
  const navigate = useNavigate();

  return (
    <div
      className={`flex flex-col h-screen overflow-hidden bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 ${theme}`}
    >
      <div
        className={`flex flex-col h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 ${theme}`}
      >
        <button
          onClick={() => navigate("/mainPage")}
          className="h-14 bg-blue-100 dark:bg-gray-800 text-gray-900 dark:text-gray-100 border-b border-gray-200 dark:border-gray-700 flex items-center px-4 z-20"
        >
          <BsHouse size={20} />
        </button>

        <div className="overflow-y-auto flex-grow">
          <div className="flex justify-center items-center h-screen bg-blue-100 dark:bg-gray-800 p-4">
            <div className="w-full max-w-4xl h-[100vh] bg-white dark:bg-gray-900 shadow-md overflow-hidden flex flex-col">
              <div className="p-8 overflow-y-auto flex-grow">
                <h2 className="text-2xl font-bold mb-3 text-gray-800 dark:text-gray-200">
                  About
                </h2>
                <p className="text-l mb-6 text-gray-800 dark:text-gray-300">
                  Responxe is a platform where you can chat with various AI
                  models. We offer a simple way to explore and use different
                  types of artificial intelligence for conversations, questions,
                  and tasks.
                </p>
                <h2 className="text-2xl font-bold mb-3 text-gray-800 dark:text-gray-200">
                  {/* <h2 className="text-2xl font-bold mb-3 text-gray-800"> */}
                  Our AI Models
                </h2>
                <p className="text-l mb-6 text-gray-800 dark:text-gray-300">
                  We provide access to AI from leading companies:
                </p>
                <ul className="list-disc list-inside mb-6 text-gray-800 dark:text-gray-300">
                  <li>Try different AI models for your questions or tasks</li>
                  <li>
                    Have ongoing conversations where the AI remembers previous
                    messages
                  </li>
                  <li>Adjust settings to personalize your experience</li>
                </ul>

                <h2 className="text-2xl font-bold mb-3 text-gray-800 dark:text-gray-200">
                  How to Start
                </h2>
                <ol className="list-decimal list-inside mb-6 text-gray-800 dark:text-gray-300">
                  <li>Visit www.Responxe.com</li>
                  <li>Create a free account</li>
                  <li>Choose an AI model and begin your conversation</li>
                </ol>

                <h2 className="text-2xl font-bold mb-3 text-gray-800 dark:text-gray-200">
                  Important Note
                </h2>
                <p className="text-l mb-6 text-gray-800 dark:text-gray-300">
                  While our AI models are advanced, they're not perfect. Always
                  verify important information and use your own judgment.
                </p>

                <h2 className="text-2xl font-bold mb-3 text-gray-800 dark:text-gray-200">
                  Join Our Community
                </h2>
                <ul className="list-disc list-inside mb-6 text-gray-800 dark:text-gray-300">
                  <li>Follow us on Twitter and LinkedIn</li>
                  <li>Join our Discord for discussions</li>
                  <li>Subscribe to our newsletter for updates</li>
                </ul>

                <h2 className="text-2xl font-bold mb-3 text-gray-800 dark:text-gray-200">
                  Contact Us
                </h2>
                <p className="text-l mb-1 text-gray-800 dark:text-gray-300">
                  We're here to help! Reach out at hello@Responxe.com or use the
                  chat feature on our website. Thank you for choosing Responxe.
                  We hope you find it useful and enjoyable.
                </p>
                <p className="text-gray-800 dark:text-gray-300">
                  The Responxe Team
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
