import React from "react";

function Benefits() {
  const benefits = [
    {
      title: "Cost-Effective",
      description: "Save more while experiencing premium AI capabilities.",
      icon: "💸",
    },
    {
      title: "Freedom from Limits",
      description: "Say goodbye to usage restrictions and timeouts.",
      icon: "🚀",
    },
    {
      title: "Productivity Boost",
      description: "Get instant answers and enhance your workflow.",
      icon: "⚡",
    },
  ];

  return (
    <section id="benefits" className="bg-gray-50 dark:bg-gray-800 py-20">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-3xl font-bold mb-12">Experience the Benefits</h2>
        <div className="flex flex-wrap -mx-4">
          {benefits.map((benefit, index) => (
            <div key={index} className="w-full md:w-1/3 px-4 mb-8">
              <div className="p-6 rounded-lg">
                <div className="text-5xl mb-4">{benefit.icon}</div>
                <h3 className="text-xl font-semibold mb-2">{benefit.title}</h3>
                <p className="text-gray-600 dark:text-gray-400">
                  {benefit.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Benefits;
