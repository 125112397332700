import React from "react";

function CTABanner() {
  return (
    <section className="bg-indigo-600 dark:bg-indigo-700 py-20">
      <div className="container mx-auto px-4 text-center text-white">
        <h2 className="text-3xl font-bold mb-8">
          Ready to Experience Unrestricted AI Access?
        </h2>
        <a
          href="/signup"
          className="bg-white text-indigo-600 px-6 py-3 rounded-md hover:bg-gray-100 font-semibold"
        >
          Sign Up Now
        </a>
      </div>
    </section>
  );
}

export default CTABanner;
