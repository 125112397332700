import React from "react";
import { Link } from "react-router-dom";

const Navigation = ({ handleModelChange, userPlanType }) => {
  console.log("userPlanType in APIColllection: ", userPlanType);

  const models = [
    { name: "GPT 4o mini", color: "emerald" },
    { name: "GPT 4o", color: "emerald" },
    { name: "OpenAI o1 preview", color: "emerald" },
    { name: "OpenAI o1 mini", color: "emerald" },
    { name: "Claude 3 haiku", color: "yellow" },
    { name: "Claude 3.5 sonnet", color: "yellow" },
    { name: "Claude 3 opus", color: "yellow" },
    { name: "Gemini 1.5 Pro", color: "blue" },
    { name: "Gemini 1.5 Flash", color: "blue" },
    { name: "Machine Learning", color: "black" },
    { name: "Programming", color: "black" },
  ];

  // Define which models are accessible for free users
  const accessibleModels = [
    "GPT 4o mini",
    "Claude 3 haiku",
    "Gemini 1.5 Flash",
  ];
  // const isFreeUser = userPlanType === "free";
  const isFreeUser = userPlanType?.toLowerCase() === "free";

  return (
    <nav className="navbar flex flex-col space-y-2 p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md">
      {models.map((model) => {
        const isAccessible = accessibleModels.includes(model.name);
        const isRestricted = isFreeUser && !isAccessible;
        let textColorClass = "text-black hover:text-black";

        // If the model is restricted, apply gray color and disable hover
        const restrictedTextColorClass = "text-gray-400 cursor-not-allowed";

        return (
          <Link
            key={model.name}
            onClick={() => handleModelChange(model.name)}
            className={`flex justify-left items-left ${
              isRestricted ? restrictedTextColorClass : textColorClass
            } ${!isRestricted ? "cursor-pointer" : ""}`}
            disabled={isRestricted} // Disable the button if restricted
            title={isRestricted ? "Upgrade your plan to access this model" : ""}
          >
            {model.name} {isRestricted && <span className="ml-2">🔒</span>}
          </Link>
        );
      })}
    </nav>
  );
};

export default Navigation;

// import React from "react";
// import { Link } from "react-router-dom";

// const Navigation = ({ handleModelChange }) => {
//   return (
//     <nav className="navbar flex flex-col space-y-2 p-4 bg-white rounded-lg shadow-md">
//       <Link
//         onClick={() => handleModelChange("GPT 4o mini")}
//         className="flex justify-center items-center text-emerald-500 hover:text-emerald-600"
//       >
//         GPT 4o mini
//       </Link>
//       <Link
//         onClick={() => handleModelChange("GPT 4o")}
//         className="flex justify-center items-center text-emerald-500 hover:text-emerald-600"
//       >
//         GPT 4o
//       </Link>
//       {/* <Link
//         onClick={() => handleModelChange("GPT o1 preview")}
//         className="flex justify-center items-center text-emerald-500 hover:text-emerald-600"
//       >
//         OpenAI o1 preview
//       </Link> */}
//       <Link
//         onClick={() => handleModelChange("GPT o1 mini")}
//         className="flex justify-center items-center text-emerald-500 hover:text-emerald-600"
//       >
//         OpenAI o1 mini
//       </Link>
//       <Link
//         onClick={() => handleModelChange("Claude 3 haiku")}
//         className="flex justify-center items-center text-yellow-700 hover:text-yellow-900"
//       >
//         Claude 3 haiku
//       </Link>
//       <Link
//         onClick={() => handleModelChange("Claude 3.5 sonnet")}
//         className="flex justify-center items-center text-yellow-700 hover:text-yellow-900"
//       >
//         Claude 3.5 sonnet
//       </Link>
//       {/* <Link
//         onClick={() => handleModelChange("Claude 3 opus")}
//         className="flex justify-center items-center text-yellow-700 hover:text-yellow-900"
//       >
//         Claude 3 opus
//       </Link> */}
//       <Link
//         onClick={() => handleModelChange("Gemini 1.5 Pro")}
//         className="flex justify-center items-center text-blue-400 hover:text-blue-700"
//       >
//         Gemini 1.5 Pro
//       </Link>
//       <Link
//         onClick={() => handleModelChange("Gemini 1.5 Flash")}
//         className="flex justify-center items-center text-blue-400 hover:text-blue-700"
//       >
//         Gemini 1.5 Flash
//       </Link>
//       <Link
//         onClick={() => handleModelChange("Claude 3 haiku")}
//         className="flex justify-center items-center text-black hover:text-black"
//       >
//         Machine Learning
//       </Link>
//       <Link
//         onClick={() => handleModelChange("Claude 3 haiku")}
//         className="flex justify-center items-center text-black hover:text-black"
//       >
//         Programming
//       </Link>
//     </nav>
//   );
// };

// export default Navigation;
