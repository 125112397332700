import React from "react";
import { useTheme } from "./ThemeContext";
import { useNavigate } from "react-router-dom";
import { BsHouse } from "react-icons/bs";

function Privacy() {
  const { theme } = useTheme();
  const navigate = useNavigate();

  return (
    <div
      className={`flex flex-col h-screen overflow-hidden bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 ${theme}`}
    >
      <div
        className={`flex flex-col h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 ${theme}`}
      >
        <button
          onClick={() => navigate("/mainPage")}
          className="h-14 bg-blue-100 dark:bg-gray-800 text-gray-900 dark:text-gray-100 border-b border-gray-200 dark:border-gray-700 flex items-center px-4 z-20"
        >
          <BsHouse size={20} />
        </button>

        <div className="overflow-y-auto flex-grow">
          <div className="flex justify-center items-center h-screen bg-blue-100 dark:bg-gray-800 p-4">
            <div className="w-full max-w-4xl h-[100vh] bg-white dark:bg-gray-900 shadow-md overflow-hidden flex flex-col">
              <div className="p-8 overflow-y-auto flex-grow">
                <ul className="list-none mb-6 text-gray-800 dark:text-gray-200">
                  <li className="mb-6">
                    <h1 className="text-2xl font-bold mb-3 text-gray-800 dark:text-gray-200">
                      Responxe.com Privacy Policy
                    </h1>
                    <h2 className="text-l font-bold text-gray-800 dark:text-gray-200 mt-4">
                      Last updated: August 14, 2024
                    </h2>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      1. Introduction
                    </h2>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-2">
                      Welcome to Responxe.com. We are committed to protecting
                      your personal information and your right to privacy. This
                      Privacy Policy describes how we collect, use, and share
                      information when you use our services.
                    </p>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      2. Information We Collect
                    </h2>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-2">
                      The information we collect from you is as follows:
                    </p>
                    <ul className="list-none mt-2 ml-6">
                      <li className="text-l text-gray-800 dark:text-gray-300">
                        <h3 className="text-xl font-bold text-gray-800 dark:text-gray-200">
                          2.1 Personal Information You Provide:
                        </h3>
                        <p className="text-l text-gray-800 dark:text-gray-300 mt-2">
                          When you create an account to use our services or
                          interact with us, we collect the following
                          information:
                        </p>
                        <ul className="list-disc list-inside mt-2 ml-6 text-gray-800 dark:text-gray-300">
                          <li>
                            Account Information: When you register, we collect
                            your name, email address, and password.
                          </li>
                          <li>
                            Profile Information: We may collect additional
                            information such as your phone number.
                          </li>
                          <li>
                            Payment Information: If you make a purchase, your
                            credit or debit card information is collected and
                            processed by Stripe, our third-party payment
                            processor.
                          </li>
                          <li>
                            User Content: We collect the questions you ask and
                            the responses you receive.
                          </li>
                          <li>
                            Other Information You Provide: We collect additional
                            information you may provide, such as you provide
                            information to verify your identity.
                          </li>
                        </ul>
                      </li>
                      <li className="text-l text-gray-800 dark:text-gray-300 mt-4">
                        <h3 className="text-xl font-bold text-gray-800 dark:text-gray-200">
                          2.2 Information Collected Automatically:
                        </h3>
                        <ul className="list-disc list-inside mt-2 ml-6 text-gray-800 dark:text-gray-300">
                          <li>
                            Usage Data: We collect the questions you ask, the
                            responses you receive, and the AI model you choose
                            so you can access your chat history at any time. You
                            also have the option to delete this information
                            whenever you want.
                          </li>
                          <li>
                            Cookies and Similar Technologies: We use cookies to
                            enhance your experience on our website and manage
                            our Services effectively. Cookies are small data
                            files that websites send to your browser. They help
                            us remember your preferences and improve site
                            functionality.
                          </li>
                        </ul>
                      </li>
                      <li className="text-l text-gray-800 dark:text-gray-300 mt-4">
                        <h3 className="text-xl font-bold text-gray-800 dark:text-gray-200">
                          2.3 File Uploads:
                        </h3>
                        <ul className="list-disc list-inside mt-2 ml-6 text-gray-800 dark:text-gray-300">
                          <li>
                            Purpose: Users may upload files (e.g., .pdf, .docx,
                            .png, .py) to ask questions about the content. These
                            files are used solely to provide responses to
                            queries.
                          </li>
                          <li>
                            Storage and Retention: Uploaded files are stored
                            temporarily and are deleted automatically when a new
                            chat begins or the current chat is closed.
                          </li>
                          <li>
                            Security: We implement security measures to protect
                            uploaded files from unauthorized access.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      3. How We Use Your Information
                    </h2>
                    <ul className="list-disc list-inside mt-2 ml-6 text-gray-800 dark:text-gray-300">
                      <li>To provide and maintain our service;</li>
                      <li>To process your payments;</li>
                      <li>
                        To respond to your inquiries and provide customer
                        support;
                      </li>
                      <li>
                        To improve our services and develop new features and
                        services;
                      </li>
                      <li>
                        To analyze usage patterns and optimize our website;
                      </li>
                      <li>
                        To communicate with you about updates or changes to our
                        service;
                      </li>
                      <li>
                        To implement robust security measures to prevent misuse
                        of our services and safeguard against fraud.
                      </li>
                    </ul>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      4. How We Share Your Information
                    </h2>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-2">
                      We do not currently share your personal information with
                      third parties, except as necessary to provide our
                      services. Such as:
                    </p>
                    <ul className="list-disc list-inside mt-2 ml-6 text-gray-800 dark:text-gray-300">
                      <li>
                        Payment Processing: We use Stripe for payment
                        processing. Stripe's privacy policy is here.
                      </li>
                      <li>
                        Cloud Storage: We use Amazon Web Services for various
                        content hosting. Amazon's privacy policy applies.
                      </li>
                      <li>
                        API Providers: Data processed by APIs like OpenAI,
                        Anthropic, and Google may be subject to the providers’
                        privacy policies.
                      </li>
                    </ul>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-4">
                      AI Model Usage:
                    </p>
                    <ul className="list-disc list-inside mt-2 ml-6">
                      <li>
                        Data Processing: User queries and uploaded files may be
                        processed by these AI models to generate responses.
                      </li>
                      <li>
                        Third-Party Services: These AI models are provided by
                        third parties (OpenAI, Google, Anthropic, etc.). While
                        we do not share your personal information directly with
                        these providers, the content of your queries and
                        uploaded files may be processed by their systems.
                      </li>
                      <li>
                        Data Retention: We retain data related to your
                        questions, answers, and chat history to allow you to
                        access and review your interactions whenever you need.
                        Uploaded files are automatically removed when a new chat
                        begins or when the current chat is closed. The specific
                        outputs generated by AI models are saved as part of your
                        chat history, enabling you to access and review these
                        outputs at any time.
                      </li>
                      <li>
                        Model-Specific Policies: Each AI model provider has its
                        own privacy policy and data handling practices. We
                        encourage you to review these policies for more
                        information on how your data may be processed:
                        <ul className="list-disc list-inside mt-2 ml-6">
                          <li>
                            OpenAI
                            <> </>
                            <a
                              href="https://openai.com/policies/privacy-policy"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 hover:text-blue-700 underline"
                            >
                              Privacy Policy
                            </a>
                          </li>
                          <li>
                            Google AI
                            <> </>
                            <a
                              href="https://ai.google/principles/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 hover:text-blue-700 underline"
                            >
                              Principles
                            </a>
                          </li>
                          <li>
                            Anthropic
                            <> </>
                            <a
                              href="https://www.anthropic.com/policies/privacy"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 hover:text-blue-700 underline"
                            >
                              Privacy Policy
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-4">
                      We regularly review and update our AI model usage to
                      ensure compliance with data protection regulations and to
                      protect your privacy.
                    </p>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      5. Additional Details on Data Collection and Processing
                    </h2>
                    <ul className="list-disc list-inside mt-2 ml-6">
                      <li>
                        Legal Considerations: To protect our services and comply
                        with legal obligations, we may use your personal data in
                        legal proceedings if necessary, such as in cases of
                        misuse of our application. We may also share information
                        with authorized government bodies if legally required.
                        Any such actions will be in accordance with applicable
                        laws.
                      </li>
                      <li>
                        Additional Data Information: You can request more
                        specific information about how we handle your personal
                        data or use our services. We are committed to
                        transparency and are happy to provide context-specific
                        explanations.
                      </li>
                      <li>
                        System Monitoring and Maintenance: We may collect
                        interaction records (system logs) or use personal data
                        like IP addresses for system maintenance and
                        improvement.
                      </li>
                      <li>
                        Techniques of Processing: To protect data from
                        unauthorized access, disclosure, modification, or
                        destruction, we enforce robust security measures.
                        Authorized personnel and third-party service providers
                        may access data when necessary. An up-to-date list of
                        these parties can be requested from us.
                      </li>
                    </ul>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      6. Data Security
                    </h2>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-2">
                      We implement several measures to help protect your
                      personal information:
                    </p>
                    <ul className="list-disc list-inside mt-2 ml-6">
                      <li>We use HTTPS encryption for our website.</li>
                      <li>
                        User email and passwords are hashed before storage.
                      </li>
                      <li>
                        We utilize secure cloud storage through Amazon AWS.
                      </li>
                      <li>
                        Payment information is handled securely by Stripe.
                      </li>
                    </ul>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-4">
                      However, no method of transmission over the Internet or
                      electronic storage is 100% secure, and we cannot guarantee
                      absolute security.
                    </p>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      7. Data Retention and Deletion
                    </h2>
                    <ul className="list-disc list-inside mt-2 ml-6">
                      <li>
                        User Content and Interaction Data: Data related to your
                        questions, answers, and chat history is retained to
                        allow you to access and retrieve your history. Users can
                        request deletion of this data.
                      </li>
                      <li>
                        Uploaded Files: Files uploaded with queries are
                        automatically removed when a new chat begins or when the
                        current chat is closed. Also, we implement robust
                        security measures to protect your uploaded files from
                        unauthorized access.
                      </li>
                    </ul>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      8. Legal Basis for Processing
                    </h2>
                    <ul className="list-disc list-inside mt-2 ml-6">
                      <li>
                        Consent: When you provide your information or use our
                        services.
                      </li>
                      <li>
                        Contractual Necessity: To fulfill our contract with you.
                      </li>
                      <li>
                        Legal Obligations: To comply with applicable laws and
                        regulations.
                      </li>
                    </ul>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      9. Data Transfers
                    </h2>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-2">
                      Data may be transferred across borders as part of using
                      services like AWS. We ensure protection of data during
                      transfer by using encryption and complying with applicable
                      data protection laws.
                    </p>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      10. Data Protection Officer (DPO)
                    </h2>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-2">
                      If applicable, we will appoint a Data Protection Officer
                      or a designated person responsible for data protection
                      matters.
                    </p>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      11. Privacy Policy Accessibility
                    </h2>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-2">
                      Our privacy policy is accessible from all pages of our
                      website. Users will be notified of any changes by posting
                      the updated privacy policy on our site and updating the
                      "Last updated" date.
                    </p>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      12. Changes to This Privacy Policy
                    </h2>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-2">
                      We may update this privacy policy from time to time. We
                      will notify you of any changes by posting the new privacy
                      policy on this page and updating the "Last updated" date.
                    </p>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      13. Contact Us
                    </h2>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-2">
                      If you have any questions about this Privacy Policy,
                      please contact us at:
                    </p>
                    <ul className="list-disc list-inside mt-2 ml-6">
                      <li>Email: gpthubai2024@gmail.com</li>
                      {/* <li>[Any other contact methods you provide]</li> */}
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
