import React from "react";
import { FaLinkedinIn } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

function Footer() {
  return (
    <footer className="bg-gray-100 dark:bg-gray-900 py-10">
      <div className="container mx-auto px-4 text-center">
        <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-8 mb-4">
          <a href="/" className="hover:text-indigo-600">
            Home
          </a>
          <a href="/myPlan" className="hover:text-indigo-600">
            Pricing
          </a>
          <a href="/contact" className="hover:text-indigo-600">
            Contact
          </a>
        </div>
        <div className="mb-4">
          <p>
            Email:{" "}
            <a
              href="mailto:support@yourwebsite.com"
              className="hover:text-indigo-600"
            >
              responxe24@gmail.com
            </a>
          </p>
        </div>
        <div className="flex justify-center space-x-4 mb-4">
          <a href="https://www.linkedin.com/company/responxe/">
            {/* <img src="/icons/facebook.svg" alt="Facebook" className="w-6 h-6" /> */}
            <FaLinkedinIn alt="LinkedIn" size={30} className="w-6 h-6" />
          </a>
          <a href="https://x.com/ResponxeAI">
            <FaXTwitter alt="X" size={30} className="w-6 h-6" />
          </a>
          <a href="#">
            <FaDiscord alt="Discord" size={30} className="w-6 h-6" />
          </a>
        </div>
        <p className="text-sm">
          &copy; 2024 Responxe.com. All rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
