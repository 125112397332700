import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ChatCategories from "./ChatCategories"; // Adjust the import path as needed

const Sidebar = ({
  error,
  savedChats,
  chatNames,
  loadChat,
  fetchSavedChats,
  serverUrl,
  userEmail,
  fetchWithAuth,
  theme,
  sidebarOpen,
  handleOpenSettingsModal,
}) => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const navigate = useNavigate();

  // Move handler functions into Sidebar
  const handleResponxe = async () => {
    try {
      navigate("/mainpage");
    } catch (error) {
      console.error("mainpage failed:", error.response || error);
      alert("Failed to go to mainpage.");
    }
  };

  const handleLogout = async () => {
    try {
      await fetchWithAuth(`${serverUrl}/api/logout`, {
        method: "POST",
      });
      localStorage.removeItem("accessToken");
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error.response || error);
      alert("Failed to log out.");
    }
  };

  const handleAbout = () => {
    navigate("/about");
  };

  const handlePrivacy = () => {
    navigate("/privacy");
  };

  const handleterms = () => {
    navigate("/terms");
  };
  const handleMyPlan = () => {
    navigate("/myPlan");
  };

  return (
    // <div
    //   className={`fixed top-0 left-0 h-full bg-gray-100 dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700 transition-all duration-300 ease-in-out z-30 ${
    //     sidebarOpen ? "w-[250px]" : "w-0"
    //   } overflow-hidden`}
    // >
    <div
      className={`fixed top-0 left-0 h-full bg-gray-100 dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700 transition-all duration-300 ease-in-out z-30 ${
        sidebarOpen ? "w-[250px]" : "w-0"
      } overflow-hidden`}
    >
      <aside className="flex flex-col h-full">
        <button
          onClick={handleResponxe}
          className="px-4 py-2 rounded text-left w-full text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
        >
          Responxe
        </button>
        <div className="flex-grow overflow-y-auto p-4">
          {error && <div className="text-red-500 mb-4">{error}</div>}
          {savedChats.length === 0 ? (
            <p className="text-gray-500 italic"></p>
          ) : (
            <ChatCategories
              savedChats={savedChats}
              chatNames={chatNames}
              loadChat={loadChat}
              fetchSavedChats={fetchSavedChats}
              serverUrl={serverUrl}
            />
          )}
        </div>
        <div className="p-4 border-t border-gray-200 dark:border-gray-700">
          <button
            onClick={() => setIsNavbarOpen(!isNavbarOpen)}
            className="flex items-center space-x-2 bg-transparent text-gray-700 dark:text-gray-200 font-bold py-2 px-0 rounded focus:outline-none focus:shadow-outline w-full"
          >
            <div className="flex items-center justify-center h-7 w-8 rounded-full bg-purple-500 text-white text-sm font-bold uppercase">
              {userEmail ? userEmail.substring(0, 2).toUpperCase() : "NN"}
            </div>
            <span className="max-w-[190px] truncate text-gray-700 dark:text-gray-200">
              {userEmail}
            </span>
          </button>

          {isNavbarOpen && (
            <nav className="mt-2 flex flex-col space-y-2">
              <Link
                to="/api/login"
                onClick={(e) => {
                  e.preventDefault();
                  handleLogout();
                }}
                className="px-4 py-2 rounded text-left w-full text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
              >
                Logout
              </Link>
              {/* <button
                onClick={handleOpenPlanModal}
                className="px-4 py-2 rounded text-left w-full text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
              > */}
              <Link
                to="/myPlan"
                onClick={(e) => {
                  e.preventDefault();
                  handleMyPlan();
                }}
                className="px-4 py-2 rounded text-left w-full text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
              >
                My Plans
              </Link>

              {/* </button> */}
              <button
                onClick={handleOpenSettingsModal}
                className="px-4 py-2 rounded text-left w-full text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
              >
                Settings
              </button>
              <Link
                to="/about"
                onClick={(e) => {
                  e.preventDefault();
                  handleAbout();
                }}
                className="px-4 py-2 rounded text-left w-full text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
              >
                About
              </Link>
              <Link
                to="/privacy"
                onClick={(e) => {
                  e.preventDefault();
                  handlePrivacy();
                }}
                className="px-4 py-2 rounded text-left w-full text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
              >
                Privacy Policy
              </Link>
              <Link
                to="/terms"
                onClick={(e) => {
                  e.preventDefault();
                  handleterms();
                }}
                className="px-4 py-2 rounded text-left w-full text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
              >
                Terms of Service
              </Link>
            </nav>
          )}
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
