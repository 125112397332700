import React from "react";
import { FiSidebar } from "react-icons/fi";

const SidebarToggleButton = ({ toggleSidebar, sidebarOpen }) => {
  return (
    <button
      onClick={toggleSidebar}
      className="mr-4 text-gray-600 dark:text-gray-400"
    >
      {sidebarOpen ? <FiSidebar size={20} /> : <FiSidebar size={20} />}
    </button>
  );
};

export default SidebarToggleButton;
