import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import React from "react";
import { ThemeProvider, useTheme } from "./ThemeContext";
import Login from "./Login";
import Signup from "./Signup";
import Dashboard from "./Dashboard";
import MainPage from "./MainPage";
import About from "./About";
import Privacy from "./Privacy";
import Terms from "./Terms";
import MyPlan from "./MyPlan";
import Landing from "./Landing";
import Contact from "./Contact";
import ResetPassword from "./ResetPassword";
import Success from "./Success";

function AppContent() {
  const { theme } = useTheme();

  return (
    <div className={`min-h-screen ${theme}`}>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/mainpage" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/mainpage" element={<MainPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/myPlan" element={<MyPlan />} />
          <Route path="/landing" element={<Landing />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/success" element={<Success />} />
        </Routes>
      </Router>
    </div>
  );
}

function App() {
  return (
    <ThemeProvider>
      <AppContent />
    </ThemeProvider>
  );
}

export default App;
