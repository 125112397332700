import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { SubmitIcon } from "./Icons";
import { FaPaperclip } from "react-icons/fa6";
import { IoCloseCircleSharp } from "react-icons/io5";
import FilePreview from "./FilePreview";

const SubmitHandler = forwardRef(
  (
    {
      question,
      setQuestion,
      gptVersion,
      chatId,
      setChatId,
      setChatName,
      selectedFiles,
      setSelectedFiles,
      setChatHistory,
      serverUrl,
      fetchWithAuth,
      setIsLoading,
      setError,
      setIsUsageLimitExceeded,
      isLoading,
      FileList,
      setIsForbidden,
      navigate,
      onQuestionDisplayed,
      themeClasses,
      sessionId,
      setSessionId,
      editIndex,
      setEditIndex,
      fetchUsageData,
    },
    ref
  ) => {
    const [isInputActive, setIsInputActive] = useState(false);
    const fileInputRef = useRef(null);
    const textareaRef = useRef(null);

    useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
        const newHeight = Math.min(textareaRef.current.scrollHeight, 200);
        textareaRef.current.style.height = `${newHeight}px`;
      }
    }, [question]);

    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));

    const handleQuestionChange = (e) => {
      setQuestion(e.target.value);
      setIsInputActive(e.target.value.length > 0);
    };

    const scrollToBottom = () => {
      if (textareaRef.current) {
        textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
      }
    };

    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        if (e.shiftKey) {
          setTimeout(scrollToBottom, 0);
        } else {
          handleSubmit(e);
        }
      }
    };

    const handleFileSelectButton = () => {
      fileInputRef.current && fileInputRef.current.click();
    };

    const handleFileSelect = (e) => {
      const files = Array.from(e.target.files);
      setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    };

    const removeFile = (index) => {
      setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const addNewChatEntry = (question, answer, files, apiModel) => {
      setChatHistory((prevHistory) => [
        ...prevHistory,
        {
          question,
          answer,
          files: files.map((file) => ({ name: file.name, type: file.type })),
          apiModel,
        },
      ]);
    };

    const initializeChat = async (message) => {
      try {
        const response = await fetchWithAuth(`${serverUrl}/api/create-chat`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            message: {
              content: message.content,
              answer: message.answer,
              files: message.files,
              apiModel: message.apiModel,
            },
            chatId: "",
          }),
        });
        const data = await response.json();
        return { chatId: data.chatId, chatName: data.chatName };
      } catch (error) {
        console.error("Error initializing chat:", error);
        throw error;
      }
    };

    const updateChat = async (messageWithChatId) => {
      try {
        await fetchWithAuth(`${serverUrl}/api/update-chat`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            chatId: messageWithChatId.chatId,
            message: {
              content: messageWithChatId.content,
              answer: messageWithChatId.answer,
              files: messageWithChatId.files,
              apiModel: messageWithChatId.apiModel,
            },
          }),
        });
      } catch (error) {
        console.error("Error updating chat:", error);
        throw error;
      }
    };

    const handleSubmit = async (
      e,
      editedQuestion = null,
      editedIndex = null
    ) => {
      e.preventDefault();
      setIsLoading(true);
      setError(null);
      const submittedQuestion = editedQuestion || question;
      const submittedIndex = editedIndex !== null ? editedIndex : -1;

      try {
        const token = localStorage.getItem("accessToken");
        if (!token) {
          throw new Error("No access token found. Please log in.");
        }
        const formData = new FormData();
        formData.append("question", submittedQuestion);
        formData.append("modelVersion", gptVersion);
        formData.append("chatId", chatId || "");
        // if (sessionId) {
        //   formData.append("sessionId", sessionId);
        // }
        // if (submittedIndex !== -1) {
        //   formData.append("editIndex", submittedIndex);
        // }
        selectedFiles.forEach((file) => formData.append("files", file));
        const response = await fetchWithAuth(`${serverUrl}/api/chat`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
          credentials: "include",
        });

        if (!response.ok) {
          if (response.status === 403) {
            const errorData = await response.json();
            if (errorData.error === "Usage limit exceeded") {
              setIsUsageLimitExceeded(true);
              throw new Error("Usage limit exceeded");
            }
          }
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let fullAnswer = "";
        let apiModel = gptVersion;

        if (submittedIndex === -1) {
          addNewChatEntry(submittedQuestion, "", selectedFiles, apiModel);
        } else {
          setChatHistory((prev) => {
            const newHistory = [...prev];
            newHistory[submittedIndex] = {
              ...newHistory[submittedIndex],
              question: submittedQuestion,
              answer: "",
              apiModel: apiModel,
            };
            return newHistory.slice(0, submittedIndex + 1);
          });
        }
        onQuestionDisplayed();

        while (true) {
          const { value, done } = await reader.read();
          if (done) break;

          const chunk = decoder.decode(value);
          const lines = chunk.split("\n");

          for (const line of lines) {
            if (line.startsWith("data: ")) {
              const data = line.slice(5).trim();

              if (data === "[DONE]") {
                setIsLoading(false);
              } else {
                try {
                  const parsedData = data.startsWith("{")
                    ? JSON.parse(data)
                    : { content: data };
                  if (parsedData.chatId) {
                    setChatId(parsedData.chatId);
                  }
                  if (parsedData.chatName) {
                    setChatName(parsedData.chatId, parsedData.chatName);
                  }
                  if (parsedData.content) {
                    fullAnswer += parsedData.content;
                    setChatHistory((prev) => {
                      const newHistory = [...prev];
                      if (submittedIndex === -1) {
                        newHistory[newHistory.length - 1].answer = fullAnswer;
                      } else {
                        newHistory[submittedIndex].answer = fullAnswer;
                      }
                      return newHistory;
                    });
                  }
                  if (parsedData.apiModel) {
                    apiModel = parsedData.apiModel;
                    setChatHistory((prev) => {
                      const newHistory = [...prev];
                      if (submittedIndex === -1) {
                        newHistory[newHistory.length - 1].apiModel = apiModel;
                      } else {
                        newHistory[submittedIndex].apiModel = apiModel;
                      }
                      return newHistory;
                    });
                  }
                  if (parsedData.error) {
                    setError(parsedData.error);
                  }
                } catch (error) {
                  console.error("Error parsing chunk:", data, error);
                }
              }
            }
          }
        }

        const message = {
          content: submittedQuestion,
          answer: fullAnswer,
          files: selectedFiles.map((f) => ({ name: f.name, type: f.type })),
          apiModel: apiModel,
        };

        if (!chatId) {
          const { chatId: newChatId, chatName } = await initializeChat(message);
          setChatId(newChatId);
          setChatName(newChatId, chatName);
        } else {
          const messageWithChatId = { ...message, chatId };
          const updatedChatName = await updateChat(messageWithChatId);
          if (updatedChatName) {
            setChatName(chatId, updatedChatName);
          }
        }
        await fetchUsageData();
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/login");
        } else if (error.response && error.response.status === 403) {
          setIsForbidden(true);
        } else {
          setError(error.message);
        }
      } finally {
        setIsLoading(false);
        setSelectedFiles([]);
        setIsInputActive(false);
        if (editedQuestion === null) {
          setQuestion("");
        } else {
          setEditIndex(null);
        }
      }
    };

    return (
      <div className="w-full">
        {selectedFiles.length > 0 && (
          <div className="p-4 rounded-t-2xl bg-gray-200 dark:bg-gray-700">
            <div className="flex flex-wrap gap-2">
              {selectedFiles.map((file, index) => (
                <div key={index} className="relative">
                  <button
                    onClick={() => removeFile(index)}
                    className="absolute -top-3 -left-3 p-1 text-gray-600 dark:text-gray-300"
                    aria-label="Remove file"
                  >
                    <IoCloseCircleSharp size={18} />
                  </button>
                  <FilePreview file={file} />
                </div>
              ))}
            </div>
            <div className="text-sm text-gray-600 dark:text-gray-300 mt-2">
              {selectedFiles.length} file{selectedFiles.length !== 1 ? "s" : ""}{" "}
              added
            </div>
          </div>
        )}
        <form
          onSubmit={handleSubmit}
          // className={`w-full flex flex-row items-center justify-between border-2 border-gray-300 dark:border-gray-600 rounded-2xl ${themeClasses} ${
          className={`w-full flex flex-col sm:flex-row items-stretch sm:items-center justify-between border-2 border-gray-300 dark:border-gray-600 rounded-2xl ${themeClasses} ${
            selectedFiles.length > 0
              ? "rounded-b-2xl border-t-0"
              : "rounded-2xl"
          }`}
        >
          {/* <div className="flex items-center flex-grow"> */}
          <div className="flex items-center flex-grow px-4 py-2">
            <button
              type="button"
              onClick={handleFileSelectButton}
              // className="ml-2 mb-2 text-gray-400 dark:text-gray-500"
              className="mr-2 text-gray-400 dark:text-gray-500"
              aria-label="Attach files"
            >
              <FaPaperclip size={23} />
            </button>
            <textarea
              ref={textareaRef}
              value={question}
              onChange={handleQuestionChange}
              onKeyDown={handleKeyDown}
              placeholder="Start a new chat..."
              className={`flex-grow mx-2 p-1 mt-3 rounded-lg focus:border-white dark:focus:border-gray-200 focus:outline-none placeholder-gray-500 dark:placeholder-gray-400 ${themeClasses}`}
              style={{
                minHeight: "40px",
                maxHeight: "200px",
                overflowY: "auto",
                resize: "none",
                width: "100%",
                // width: "calc(100% - 60px)",
              }}
            />
          </div>
          <input
            type="file"
            multiple
            ref={fileInputRef}
            onChange={handleFileSelect}
            style={{ display: "none" }}
          />
          <button
            type="submit"
            disabled={isLoading}
            //   className={`p-1 mr-2 rounded-md ${
            //     isLoading
            //       ? "bg-stone-300 dark:bg-stone-600"
            //       : isInputActive
            //       ? "bg-stone-400 dark:bg-stone-700"
            //       : "bg-stone-300 dark:bg-stone-600"
            //   } text-white`}
            // >
            className={`flex items-center justify-center p-1 mr-2 rounded-md ${
              isLoading
                ? "bg-stone-300 dark:bg-stone-600"
                : isInputActive
                ? "bg-stone-400 dark:bg-stone-700"
                : "bg-stone-300 dark:bg-stone-600"
            } text-white`}
          >
            {isLoading ? <ClipLoader size={20} color="#fff" /> : <SubmitIcon />}
            {/* </div> */}
          </button>
        </form>
      </div>
    );
  }
);

export default SubmitHandler;
