import React, { useState, useCallback } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { FiCopy, FiCheck } from "react-icons/fi";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const CodeBlockHandler = ({ answer }) => {
  const [copiedCode, setCopiedCode] = useState(null);

  const copyToClipboard = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      setCopiedCode(code);
      setTimeout(() => setCopiedCode(null), 2000);
    });
  };

  return (
    // <div className="text-base text-gray-800 leading-relaxed">
    <div className="text-base text-gray-800 dark:text-gray-200 leading-relaxed">
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          p: ({ node, ...props }) => <p className="mb-4" {...props} />,
          a: ({ node, ...props }) => (
            <a
              // className="text-blue-600 underline hover:text-blue-800"
              className="text-blue-600 dark:text-blue-400 underline hover:text-blue-800 dark:hover:text-blue-600"
              target="_blank"
              rel="noopener noreferrer"
              {...props}
            />
          ),
          strong: ({ node, ...props }) => (
            <strong className="font-semibold" {...props} />
          ),
          em: ({ node, ...props }) => <em className="italic" {...props} />,
          blockquote: ({ node, ...props }) => (
            <blockquote
              // className="border-l-4 border-gray-300 pl-4 italic text-gray-600 mb-4"
              className="border-l-4 border-gray-300 dark:border-gray-600 pl-4 italic text-gray-600 dark:text-gray-400 mb-4"
              {...props}
            />
          ),
          ul: ({ node, ...props }) => (
            <ul className="list-disc pl-5 mb-4" {...props} />
          ),
          ol: ({ node, ...props }) => (
            <ol className="list-decimal pl-5 mb-4" {...props} />
          ),
          li: ({ node, ...props }) => (
            <li className="mb-2">{props.children}</li>
          ),
          code({ node, inline, className, children, ...props }) {
            const languageMatch = /language-(\w+)/.exec(className || "");
            const codeContent = String(children).replace(/\n$/, "");

            if (!inline && languageMatch) {
              return (
                <div className="relative my-4">
                  {/* <div className="flex justify-between items-center bg-gray-800 text-gray-200 px-4 py-2 rounded-t-md"> */}
                  <div className="flex justify-between items-center bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-200 px-4 py-2 rounded-t-md">
                    <span className="text-sm font-medium">
                      {languageMatch[1]}
                    </span>
                    <button
                      onClick={() => copyToClipboard(codeContent)}
                      // className="flex items-center text-gray-400 hover:text-white focus:outline-none"
                      className="flex items-center text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-white focus:outline-none"
                      aria-label="Copy code to clipboard"
                    >
                      {copiedCode === codeContent ? (
                        <FiCheck className="w-5 h-5 text-green-500 dark:text-green-400" />
                      ) : (
                        <FiCopy className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                  <SyntaxHighlighter
                    language={languageMatch[1]}
                    style={atomDark}
                    customStyle={{
                      margin: 0,
                      padding: "1rem",
                      borderRadius: "0 0 0.375rem 0.375rem",
                    }}
                    {...props}
                  >
                    {codeContent}
                  </SyntaxHighlighter>
                </div>
              );
            } else if (inline) {
              return (
                <code
                  // className="bg-gray-100 text-gray-800 px-1 rounded"
                  className="bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-200 px-1 rounded"
                  {...props}
                >
                  {children}
                </code>
              );
            }
            return (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
        }}
      >
        {answer}
      </ReactMarkdown>
    </div>
  );
};

export default CodeBlockHandler;
