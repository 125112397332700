import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";

function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [emailToResend, setEmailToResend] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [promoError, setPromoError] = useState("");
  const navigate = useNavigate();
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  const handleResendVerification = async () => {
    try {
      const response = await axios.post(
        `${serverUrl}/api/resend-verification`,
        {
          email: emailToResend,
        }
      );
      const message =
        typeof response.data === "string"
          ? response.data
          : response.data.message;
      alert(
        message || "Verification email sent again. Please check your inbox."
      );
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.response?.data ||
        "An error occurred while trying to resend the verification email.";
      alert(errorMessage);
    }
    setShowModal(false); // Hide modal after sending email
  };
  const resendVerificationModal = showModal && (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
      id="my-modal"
    >
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Resend Verification Email
          </h3>
          <div className="mt-2 px-4 py-3">
            <input
              type="email"
              className="px-4 py-2 border-2 border-gray-300 w-full rounded-md"
              placeholder="Enter your email"
              value={emailToResend}
              onChange={(e) => setEmailToResend(e.target.value)}
            />
          </div>
          <div className="items-center px-4 py-3">
            <button
              className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
              onClick={handleResendVerification}
            >
              Resend Email
            </button>
            <button
              className="mt-3 px-4 py-2 bg-gray-300 text-gray-700 text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true); // Mark as submitted to trigger validation checks
    setPromoError("");

    if (!email || password.length < 3) {
      alert("Please fill in the form correctly.");
      return;
    }

    try {
      const response = await axios.post(`${serverUrl}/api/signup`, {
        email,
        password,
        promoCode,
      });
      alert(
        response.data.message ||
          "Signup successful! Please check your email to verify your account."
      );
      navigate("/login"); // Adjust according to your route settings
    } catch (error) {
      if (error.response?.data?.promoError) {
        setPromoError(error.response.data.promoError);
      } else {
        alert(error.response?.data || "An error occurred during signup.");
      }
    }
  };

  const emailNotValid = submitted && !email.includes("@");
  const passwordNotValid = submitted && password.trim().length < 3;

  return (
    <div className="flex justify-center items-center min-h-screen bg-blue-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-sm">
        <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">
          Signup
        </h2>
        <div className="">
          <form
            onSubmit={handleSubmit}
            className="space-y-4"
            action="/signup"
            method="POST"
          >
            <div>
              <label>Email:</label>
              <input
                placeholder="*Email"
                className="border-2 border-gray-300 p-3 w-full rounded-md focus:border-purple-500"
                type="email"
                value={email}
                style={{
                  backgroundColor: emailNotValid ? "#fed2d2" : "#d1d5db",
                }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label>Password:</label>
              <input
                className="border-2 border-gray-300 p-3 w-full rounded-md focus:border-purple-500"
                placeholder="*Password"
                type="password"
                value={password}
                style={{
                  backgroundColor: passwordNotValid ? "#fed2d2" : "#d1d5db",
                }}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div>
              <label>Promo Code (optional):</label>
              <input
                className="border-2 border-gray-300 p-3 w-full rounded-md focus:border-purple-500"
                placeholder="Promo Code"
                type="text"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
              />
              {promoError && (
                <p className="text-red-500 text-sm mt-1">{promoError}</p>
              )}
            </div>
            <button
              type="submit"
              className="w-full bg-purple-600 text-white p-3 rounded-md hover:bg-purple-700 focus:outline-none"
            >
              Signup
            </button>
            <div className="text-center">
              <a
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  setShowModal(true);
                }}
                className="text-purple-600 hover:underline"
              >
                Resend Verification Email
              </a>
            </div>
          </form>
          <p className="mt-4 text-center">
            Already a member?
            <Link to="/login" className="text-purple-600 hover:underline">
              {" "}
              Log in
            </Link>
          </p>
          {showModal && resendVerificationModal}
        </div>
      </div>
    </div>
  );
}

export default Signup;
