import React, { useState, useEffect } from "react";
import { useTheme } from "./ThemeContext";
import ThemeSwitcher from "./ThemeSwitcher";
import { IoCloseCircleSharp } from "react-icons/io5";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Settings = ({
  setShowSettingsModal,
  userEmail,
  serverUrl,
  fetchWithAuth,
}) => {
  const [email, setEmail] = useState(userEmail);
  const [planType, setPlanType] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const { theme } = useTheme();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetchWithAuth(`${serverUrl}/api/user-data`);
        if (response.ok) {
          const userData = await response.json();
          setPlanType(userData.planType);
          setPhoneNumber(userData.phoneNumber || "");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [fetchWithAuth, serverUrl]);

  const handlePhoneNumberChange = (value, country) => {
    setPhoneNumber(value);
  };

  const handleSavePhoneNumber = async () => {
    try {
      const response = await fetchWithAuth(`${serverUrl}/api/update-phone`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phoneNumber }),
      });

      if (response.ok) {
        alert("Phone number updated successfully");
      } else {
        alert("Failed to update phone number");
      }
    } catch (error) {
      console.error("Error updating phone number:", error);
      alert("An error occurred while updating phone number");
    }
  };

  return (
    // <div
    //   className={`bg-white dark:bg-gray-800 text-gray-800 dark:text-white p-6 rounded-lg shadow-xl max-w-md w-full relative ${theme}`}
    // >
    <div
      className={`bg-white dark:bg-gray-800 text-gray-800 dark:text-white p-6 rounded-lg shadow-xl max-w-md w-full relative ${theme}`}
    >
      <button
        onClick={() => setShowSettingsModal(false)}
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-white"
        // className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
      >
        <IoCloseCircleSharp size={24} />
      </button>
      <h1 className="text-2xl font-bold mb-6">Settings</h1>

      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Email</h2>
        <p className="text-gray-700 dark:text-gray-300">{email}</p>
      </div>

      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Plan Type</h2>
        <p className="text-gray-700 dark:text-gray-300">
          {planType || "Loading..."}
        </p>
      </div>

      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Phone Number</h2>
        {/* <div className="flex mb-6 relative"> */}
        <div
          className={`flex mb-6 relative ${
            theme === "dark"
              ? "bg-gray-800 text-gray-200"
              : "bg-white text-black"
          }`}
        >
          <PhoneInput
            className={`rounded ${
              theme === "dark"
                ? "bg-gray-700 text-gray-200 border-gray-600"
                : "bg-white text-black border-gray-300"
            }`}
            country={"us"}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            inputStyle={{
              width: "100%",
              height: "40px",
              fontSize: "16px",
              paddingLeft: "48px",
              borderRadius: "4px",
              border: `1px solid ${theme === "dark" ? "#4b5563" : "#d1d5db"}`, // Adjust border based on theme
              backgroundColor: theme === "dark" ? "#374151" : "#fff",
              color: theme === "dark" ? "#d1d5db" : "#000",
            }}
            containerStyle={{
              width: "70%",
            }}
            buttonStyle={{
              backgroundColor: theme === "dark" ? "#374151" : "#fff", // Change background color of flag dropdown button
              border: `1px solid ${theme === "dark" ? "#4b5563" : "#d1d5db"}`, // Adjust border for the dropdown button
            }}
            dropdownStyle={{
              width: "300px",
              backgroundColor: theme === "dark" ? "#374151" : "#fff",
              color: theme === "dark" ? "#d1d5db" : "#000",
            }}
          />
          <button
            onClick={handleSavePhoneNumber}
            className="bg-blue-500 text-white px-4 py-2 ml-2 rounded hover:bg-blue-600 dark:hover:bg-blue-800 h-[40px]"
          >
            Save
          </button>
        </div>
      </div>

      <div className="mb-6">
        <ThemeSwitcher className="text-xl font-semibold mb-2" />
      </div>
    </div>
  );
};

export default Settings;
