import React, { useEffect, useState } from "react";

function Header() {
  const [theme, setTheme] = useState(null);
  useEffect(() => {
    // Check for saved user theme, if not, check for system preference
    const userTheme = localStorage.getItem("theme");
    if (userTheme) {
      setTheme(userTheme);
      document.documentElement.classList.add(userTheme);
    } else {
      setTheme("light");
      document.documentElement.classList.add("light");
    }
  }, []);

  return (
    <header className="bg-white dark:bg-gray-800 shadow">
      <nav className="container mx-auto flex items-center justify-between p-4">
        {/* Brand Logo */}
        <div>
          <a href="/" className="text-2xl font-bold">
            Responxe
          </a>
        </div>
        <div className="flex items-center space-x-4">
          <ul className="flex space-x-4">
            <li>
              <a href="#features" className="hover:text-indigo-600">
                Features
              </a>
            </li>
            <li>
              <a href="#benefits" className="hover:text-indigo-600">
                Benefits
              </a>
            </li>
            <li>
              <a href="#comparison" className="hover:text-indigo-600">
                Comparison
              </a>
            </li>
          </ul>
          {/* <div className="flex items-center space-x-2">
            <a href="/contact" className="hover:text-indigo-600">
              Contact
            </a>
          </div> */}
        </div>
      </nav>
    </header>
  );
}

export default Header;
