// MyPlan.jsx
import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation } from "react-router-dom";
import { BsHouse } from "react-icons/bs";
import { useTheme } from "./ThemeContext";
import { useNavigate } from "react-router-dom";

function MyPlan() {
  const [activeTab, setActiveTab] = useState("Professional");
  const [notification, setNotification] = useState(""); // New state for notifications
  const location = useLocation();
  const { theme } = useTheme();
  const navigate = useNavigate();
  const userEmail = location.state?.userEmail;

  const makePayment = async (plan) => {
    if (plan.planName === "recharge") {
      setNotification("You need to first subscribe to a plan.");
      return;
    }

    // Clear any existing notifications
    setNotification("");

    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
    const serverUrl = process.env.REACT_APP_SERVER_URL;

    const body = {
      email: userEmail,
      products: [plan],
    };

    const token = localStorage.getItem("accessToken");

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      console.log("Sending request to server...");
      const response = await fetch(`${serverUrl}/api/create-checkout-session`, {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to create checkout session");
      }

      const session = await response.json();
      const result = await stripe.redirectToCheckout({ sessionId: session.id });

      if (result.error) {
        console.error("Error in redirectToCheckout:", result.error);
        setNotification("Payment failed. Please try again.");
      }
    } catch (error) {
      console.error("Error in makePayment:", error);
      setNotification("An unexpected error occurred. Please try again later.");
    }
  };

  const plans = {
    Recharge: [
      {
        name: "Recharge",
        planName: "recharge",
        price: 4.99,
        description: [
          // "✔️ Add $4 to your usage cap",
          // "✔️ Covers Stripe and AWS fees",
        ],
      },
    ],

    Basic: [
      {
        name: "Monthly",
        planName: "basic_monthly",
        price: 9.99,
        description: [
          "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
        ],
      },
      {
        name: "Quarterly",
        planName: "basic_quarterly",
        price: 26.99,
        usage: "2000 tokens",
        description: [
          "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
          "✔️ Enjoy higher monthly message limits",
        ],
      },
      {
        name: "Yearly",
        planName: "basic_yearly",
        price: 99.99,
        usage: "3000 tokens",
        description: [
          "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
          "✔️ Enjoy higher monthly message limits",
        ],
      },
    ],
    Professional: [
      {
        name: "Monthly",
        planName: "pro_monthly",
        price: 49.99,
        usage: "12000 tokens",
        description: [
          "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
          "✔️ Enjoy higher monthly message limits",
        ],
      }, // 2 months free
      {
        name: "Quarterly",
        planName: "pro_quarterly",
        price: 129.99,
        apiVersion: "GPT-4-turbo",
        usage: "24000 tokens",
        description: [
          "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
          "✔️ Enjoy higher monthly message limits",
        ],
      },
      {
        name: "Yearly",
        planName: "pro_yearly",
        price: 499.99,
        apiVersion: "Gemini",
        usage: "36000 tokens",
        description: [
          "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
          "✔️ Enjoy higher monthly message limits",
        ],
      },
    ],
  };

  return (
    <div
      className={`flex flex-col h-screen overflow-hidden bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 ${theme}`}
    >
      <div
        className={`flex flex-col h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 ${theme}`}
      >
        <button
          onClick={() => navigate("/mainPage")}
          className="h-14 bg-blue-100 dark:bg-gray-800 text-gray-900 dark:text-gray-100 dark:border-gray-700 flex items-center px-4 z-20"
        >
          <BsHouse size={20} />
        </button>

        <div className="fixed inset-0 bg-blue-100 bg-opacity-75 flex items-center justify-center p-4 dark:bg-gray-900 dark:bg-opacity-80">
          <div className="dark:bg-gray-800 rounded-lg shadow-xl overflow-hidden max-w-4xl w-full h-[500px] flex flex-col relative">
            <div className="flex justify-between px-5 pt-5 border-b dark:border-gray-700">
              <button
                className={`text-lg font-medium ml-40 ${
                  activeTab === "Basic"
                    ? "text-blue-600 border-b-2 border-blue-600 dark:text-blue-400 dark:border-blue-400"
                    : "text-gray-500 dark:text-gray-400"
                }`}
                onClick={() => setActiveTab("Basic")}
              >
                ResponXe Pro
              </button>
              <button
                className={`text-lg font-medium mr-40 ${
                  activeTab === "Professional"
                    ? "text-blue-600 border-b-2 border-blue-600 dark:text-blue-400 dark:border-blue-400"
                    : "text-gray-500 dark:text-gray-400"
                }`}
                onClick={() => setActiveTab("Professional")}
              >
                ResponXe Premium
              </button>
              <button
                className={`text-lg font-medium mr-40 ${
                  activeTab === "Recharge"
                    ? "text-blue-600 border-b-2 border-blue-600 dark:text-blue-400 dark:border-blue-400"
                    : "text-gray-500 dark:text-gray-400"
                }`}
                onClick={() => setActiveTab("Recharge")}
              >
                Recharge
              </button>
            </div>

            {/* Notification Section */}
            {notification && (
              <div className="px-5 pt-4">
                <div
                  className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <strong className="font-bold">Notice: </strong>
                  <span className="block sm:inline">{notification}</span>
                  <span
                    className="absolute top-0 bottom-0 right-0 px-4 py-3"
                    onClick={() => setNotification("")}
                  >
                    <svg
                      className="fill-current h-6 w-6 text-red-500"
                      role="button"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <title>Close</title>
                      <path d="M14.348 5.652a.5.5 0 00-.707 0L10 9.293 6.36 5.652a.5.5 0 10-.707.707L9.293 10l-3.64 3.64a.5.5 0 00.707.707L10 10.707l3.64 3.64a.5.5 0 00.707-.707L10.707 10l3.64-3.64a.5.5 0 000-.708z" />
                    </svg>
                  </span>
                </div>
              </div>
            )}

            <div className="flex items-center justify-center flex-1 p-5">
              {activeTab === "Basic" && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {plans.Basic.map((plan) => (
                    <div
                      key={plan.name}
                      className="bg-white dark:bg-gray-700 p-5 rounded-lg shadow-md text-center border border-gray-200 dark:border-gray-600 w-64 flex flex-col h-full"
                    >
                      <div className="flex-grow">
                        <h3 className="text-xl font-bold text-gray-900 dark:text-gray-200 pt-5">
                          {plan.name}
                        </h3>
                        <p className="mt-2 text-2xl font-bold text-gray-900 dark:text-gray-200 pt-5">
                          ${plan.price}
                          <span className="text-base font-medium text-gray-500 dark:text-gray-400">
                            {"/mo"}
                          </span>
                        </p>
                        <div className="mt-2 text-left pt-5">
                          {Array.isArray(plan.description) ? (
                            plan.description.map((paragraph, index) => (
                              <p
                                key={index}
                                className="font-small text-gray-700 dark:text-gray-300 mb-2"
                              >
                                {paragraph}
                              </p>
                            ))
                          ) : (
                            <p className="font-small text-gray-700 dark:text-gray-300 mb-2">
                              {plan.description}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="mt-auto pt-5">
                        <button
                          className="w-full bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-800"
                          onClick={() => makePayment(plan)}
                        >
                          Get Started
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {activeTab === "Professional" && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {plans.Professional.map((plan) => (
                    <div
                      key={plan.name}
                      className="bg-white dark:bg-gray-700 p-5 rounded-lg shadow-md text-center border border-gray-200 dark:border-gray-600 w-64 flex flex-col h-full"
                    >
                      <div className="flex-grow">
                        <h3 className="text-xl font-bold text-gray-900 dark:text-gray-200 pt-5">
                          {plan.name}
                        </h3>
                        <p className="mt-2 text-2xl font-bold text-gray-900 dark:text-gray-200 pt-5">
                          ${plan.price}
                          <span className="text-base font-medium text-gray-500 dark:text-gray-400">
                            {"/mo"}
                          </span>
                        </p>
                        <div className="mt-2 text-left pt-5">
                          {Array.isArray(plan.description) ? (
                            plan.description.map((paragraph, index) => (
                              <p
                                key={index}
                                className="font-small text-gray-700 dark:text-gray-300 mb-2"
                              >
                                {paragraph}
                              </p>
                            ))
                          ) : (
                            <p className="font-small text-gray-700 dark:text-gray-300 mb-2">
                              {plan.description}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="mt-auto pt-5">
                        <button
                          className="w-full bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-800"
                          onClick={() => makePayment(plan)}
                        >
                          Get Started
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {activeTab === "Recharge" && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {plans.Recharge.map((plan) => (
                    <div
                      key={plan.name}
                      className="bg-white dark:bg-gray-700 p-5 rounded-lg shadow-md text-center border border-gray-200 dark:border-gray-600 w-64 flex flex-col h-full"
                    >
                      <div className="flex-grow">
                        <h3 className="text-xl font-bold text-gray-900 dark:text-gray-200 pt-5">
                          {plan.name}
                        </h3>
                        <p className="mt-2 text-2xl font-bold text-gray-900 dark:text-gray-200 pt-5">
                          ${plan.price}
                          <span className="text-base font-medium text-gray-500 dark:text-gray-400">
                            {"/one-time"}
                          </span>
                        </p>
                        <div className="mt-2 text-left pt-5">
                          {Array.isArray(plan.description) ? (
                            plan.description.map((paragraph, index) => (
                              <p
                                key={index}
                                className="font-small text-gray-700 dark:text-gray-300 mb-2"
                              >
                                {paragraph}
                              </p>
                            ))
                          ) : (
                            <p className="font-small text-gray-700 dark:text-gray-300 mb-2">
                              {plan.description}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="mt-auto pt-5">
                        <button
                          className="w-full bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-800"
                          onClick={() => makePayment(plan)}
                        >
                          Recharge
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyPlan;

// import React, { useState } from "react";
// import { loadStripe } from "@stripe/stripe-js";
// import { useLocation } from "react-router-dom";
// import { BsHouse } from "react-icons/bs";
// import { useTheme } from "./ThemeContext";
// import { useNavigate } from "react-router-dom";

// function MyPlan() {
//   const [activeTab, setActiveTab] = useState("Professional");
//   const location = useLocation();
//   const { theme } = useTheme();
//   const navigate = useNavigate();
//   const userEmail = location.state?.userEmail;

//   const makePayment = async (plan) => {
//     const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
//     const serverUrl = process.env.REACT_APP_SERVER_URL;

//     const body = {
//       email: userEmail,
//       products: [plan],
//     };

//     const token = localStorage.getItem("accessToken");

//     const headers = {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`,
//     };

//     try {
//       console.log("Sending request to server...");
//       const response = await fetch(`${serverUrl}/api/create-checkout-session`, {
//         method: "POST",
//         headers,
//         body: JSON.stringify(body),
//       });

//       if (!response.ok) {
//         // throw new Error(`HTTP error! status: ${response.status}`);
//         const errorData = await response.json();
//         throw new Error(errorData.error || "Failed to create checkout session");
//       }

//       const session = await response.json();
//       const result = await stripe.redirectToCheckout({ sessionId: session.id });

//       if (result.error) {
//         console.error("Error in redirectToCheckout:", result.error);
//       }
//     } catch (error) {
//       console.error("Error in makePayment:", error);
//       // Handle the error appropriately, e.g., show an error message to the user
//     }
//   };

//   const plans = {
//     Recharge: [
//       {
//         name: "Recharge",
//         planName: "recharge",
//         price: 4.99, // $4.99 for $4 added
//         description: [
//           "✔️ Add $4 to your usage cap",
//           "✔️ Covers Stripe and AWS fees",
//         ],
//       },
//     ],

//     Basic: [
//       {
//         name: "Mothly",
//         planName: "basic_monthly",
//         price: 9.99,
//         // usage: "1000 tokens",
//         description: [
//           "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
//         ],
//       },
//       {
//         name: "Quarterly",
//         planName: "basic_quarterly",

//         price: 26.99,
//         usage: "2000 tokens",
//         description: [
//           "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
//           "✔️ Enjoy higher monthly message limits",
//         ],
//       },
//       {
//         name: "Yearly",
//         planName: "basic_yearly",

//         price: 99.99,
//         usage: "3000 tokens",
//         description: [
//           "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
//           "✔️ Enjoy higher monthly message limits",
//         ],
//       },
//     ],
//     Professional: [
//       {
//         name: "Monthly",
//         planName: "pro_monthly",

//         price: 49.99,
//         usage: "12000 tokens",
//         description: [
//           "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
//           "✔️ Enjoy higher monthly message limits",
//         ],
//       }, // 2 months free
//       {
//         name: "Quarterly",
//         planName: "pro_quarterly",
//         price: 129.99,
//         apiVersion: "GPT-4-turbo",
//         usage: "24000 tokens",
//         description: [
//           "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
//           "✔️ Enjoy higher monthly message limits",
//         ],
//       },
//       {
//         name: "Yearly",
//         planName: "pro_yearly",
//         price: 499.99,
//         apiVersion: "Gemini",
//         usage: "36000 tokens",
//         description: [
//           "✔️ Use advanced Gen AI models, like Claude 3.5 Sonnet, GPT4o, Gemini 1.5 Pro, and others",
//           "✔️ Enjoy higher monthly message limits",
//         ],
//       },
//     ],
//   };

//   return (
//     <div
//       className={`flex flex-col h-screen overflow-hidden bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 ${theme}`}
//     >
//       <div
//         className={`flex flex-col h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 ${theme}`}
//       >
//         <button
//           onClick={() => navigate("/mainPage")}
//           className="h-14 bg-blue-100 dark:bg-gray-800 text-gray-900 dark:text-gray-100 dark:border-gray-700 flex items-center px-4 z-20"
//         >
//           <BsHouse size={20} />
//         </button>

//         <div className="fixed inset-0 bg-blue-100 bg-opacity-75 flex items-center justify-center p-4 dark:bg-gray-900 dark:bg-opacity-80">
//           <div className="dark:bg-gray-800 rounded-lg shadow-xl overflow-hidden max-w-4xl w-full h-[500px] flex flex-col relative">
//             <div className="flex justify-between px-5 pt-5 border-b dark:border-gray-700">
//               <button
//                 className={`text-lg font-medium ml-40 ${
//                   activeTab === "Basic"
//                     ? "text-blue-600 border-b-2 border-blue-600 dark:text-blue-400 dark:border-blue-400"
//                     : "text-gray-500 dark:text-gray-400"
//                 }`}
//                 onClick={() => setActiveTab("Basic")}
//               >
//                 ResponXe Pro
//               </button>
//               <button
//                 className={`text-lg font-medium mr-40 ${
//                   activeTab === "Professional"
//                     ? "text-blue-600 border-b-2 border-blue-600 dark:text-blue-400 dark:border-blue-400"
//                     : "text-gray-500 dark:text-gray-400"
//                 }`}
//                 onClick={() => setActiveTab("Professional")}
//               >
//                 ResponXe Premium
//               </button>
//               <button
//                 className={`text-lg font-medium mr-40 ${
//                   activeTab === "Recharge"
//                     ? "text-blue-600 border-b-2 border-blue-600 dark:text-blue-400 dark:border-blue-400"
//                     : "text-gray-500 dark:text-gray-400"
//                 }`}
//                 onClick={() => setActiveTab("Recharge")}
//               >
//                 Recharge
//               </button>
//             </div>

//             {/* <div className="flex items-center justify-center flex-1 p-5"> */}
//             <div className="flex items-center justify-center flex-1 p-5">
//               {activeTab === "Basic" && (
//                 <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
//                   {plans.Basic.map((plan) => (
//                     <div
//                       key={plan.name}
//                       className="bg-white dark:bg-gray-700 p-5 rounded-lg shadow-md text-center border border-gray-200 dark:border-gray-600 w-64 flex flex-col h-full"
//                     >
//                       <div className="flex-grow">
//                         <h3 className="text-xl font-bold text-gray-900 dark:text-gray-200 pt-5">
//                           {plan.name}
//                         </h3>
//                         <p className="mt-2 text-2xl font-bold text-gray-900 dark:text-gray-200 pt-5">
//                           ${plan.price}
//                           <span className="text-base font-medium text-gray-500 dark:text-gray-400">
//                             {"/mo"}
//                           </span>
//                         </p>
//                         <div className="mt-2 text-left pt-5">
//                           {Array.isArray(plan.description) ? (
//                             plan.description.map((paragraph, index) => (
//                               <p
//                                 key={index}
//                                 className="font-small text-gray-700 dark:text-gray-300 mb-2"
//                               >
//                                 {paragraph}
//                               </p>
//                             ))
//                           ) : (
//                             <p className="font-small text-gray-700 dark:text-gray-300 mb-2">
//                               {plan.description}
//                             </p>
//                           )}
//                         </div>
//                       </div>
//                       <div className="mt-auto pt-5">
//                         <button
//                           className="w-full bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-800"
//                           onClick={() => makePayment(plan)}
//                         >
//                           Get Started
//                         </button>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               )}

//               {activeTab === "Professional" && (
//                 <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
//                   {plans.Professional.map((plan) => (
//                     <div
//                       key={plan.name}
//                       className="bg-white dark:bg-gray-700 p-5 rounded-lg shadow-md text-center border border-gray-200 dark:border-gray-600 w-64 flex flex-col h-full"
//                     >
//                       <div className="flex-grow">
//                         <h3 className="text-xl font-bold text-gray-900 dark:text-gray-200 pt-5">
//                           {plan.name}
//                         </h3>
//                         <p className="mt-2 text-2xl font-bold text-gray-900 dark:text-gray-200 pt-5">
//                           ${plan.price}
//                           <span className="text-base font-medium text-gray-500 dark:text-gray-400">
//                             {"/mo"}
//                           </span>
//                         </p>
//                         <div className="mt-2 text-left pt-5">
//                           {Array.isArray(plan.description) ? (
//                             plan.description.map((paragraph, index) => (
//                               <p
//                                 key={index}
//                                 className="font-small text-gray-700 dark:text-gray-300 mb-2"
//                               >
//                                 {paragraph}
//                               </p>
//                             ))
//                           ) : (
//                             <p className="font-small text-gray-700 dark:text-gray-300 mb-2">
//                               {plan.description}
//                             </p>
//                           )}
//                         </div>
//                       </div>
//                       <div className="mt-auto pt-5">
//                         <button
//                           className="w-full bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-800"
//                           onClick={() => makePayment(plan)}
//                         >
//                           Get Started
//                         </button>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               )}
//               {activeTab === "Recharge" && (
//                 <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
//                   {plans.Recharge.map((plan) => (
//                     <div
//                       key={plan.name}
//                       className="bg-white dark:bg-gray-700 p-5 rounded-lg shadow-md text-center border border-gray-200 dark:border-gray-600 w-64 flex flex-col h-full"
//                     >
//                       <div className="flex-grow">
//                         <h3 className="text-xl font-bold text-gray-900 dark:text-gray-200 pt-5">
//                           {plan.name}
//                         </h3>
//                         <p className="mt-2 text-2xl font-bold text-gray-900 dark:text-gray-200 pt-5">
//                           ${plan.price}
//                           <span className="text-base font-medium text-gray-500 dark:text-gray-400">
//                             {"/one-time"}
//                           </span>
//                         </p>
//                         <div className="mt-2 text-left pt-5">
//                           {Array.isArray(plan.description) ? (
//                             plan.description.map((paragraph, index) => (
//                               <p
//                                 key={index}
//                                 className="font-small text-gray-700 dark:text-gray-300 mb-2"
//                               >
//                                 {paragraph}
//                               </p>
//                             ))
//                           ) : (
//                             <p className="font-small text-gray-700 dark:text-gray-300 mb-2">
//                               {plan.description}
//                             </p>
//                           )}
//                         </div>
//                       </div>
//                       <div className="mt-auto pt-5">
//                         <button
//                           className="w-full bg-blue-500 text-white rounded-md px-4 py-2 hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-800"
//                           onClick={() => makePayment(plan)}
//                         >
//                           Recharge
//                         </button>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default MyPlan;
