import React from "react";
import { BsTrash3 } from "react-icons/bs";
import { FiEdit2 } from "react-icons/fi";
import CodeBlockHandler from "./CodeBlockHandler";
// import TypingText from "./TypingText";

const ChatHistoryDisplay = ({
  chatHistory,
  handleDeleteChat,
  handleEditChat,
  editIndex,
  editedQuestion,
  setEditedQuestion,
  submitHandlerRef,
  setEditIndex,
  handleQuestionDisplayed,
  getFileIcon,
}) => (
  <div className="space-y-4">
    {chatHistory.map((chat, chatIndex) => (
      <div key={chatIndex} className="p-4 relative">
        <div className="flex items-center justify-between mb-0 bg-gray-100 dark:bg-gray-800 pr-3 pb-0 pl-3 pt-3 rounded-t-md">
          <span className="text-[16px] font-bold text-gray-800 dark:text-gray-200">
            Question:
          </span>
          <div className="flex items-center space-x-2">
            <BsTrash3
              className="cursor-pointer text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-600"
              onClick={() => handleDeleteChat(chatIndex)}
            />
            <FiEdit2
              className="cursor-pointer text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-600"
              onClick={() =>
                handleEditChat(
                  chatIndex,
                  chat.question || chat.content,
                  chat.apiModel
                )
              }
            />
          </div>
        </div>

        {editIndex === chatIndex ? (
          <div>
            <pre
              className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-t-md font-sans text-[16px] leading-[1.5] text-gray-800 dark:text-gray-200 whitespace-pre-wrap"
              style={{ fontFamily: '"Inter", sans-serif' }}
            >
              <textarea
                value={editedQuestion}
                onChange={(e) => setEditedQuestion(e.target.value)}
                className="w-full h-full bg-transparent resize-none outline-none text-gray-800 dark:text-gray-200"
                style={{
                  fontFamily: "inherit",
                  fontSize: "inherit",
                  lineHeight: "inherit",
                }}
              />
            </pre>
            <button
              onClick={(e) => {
                submitHandlerRef.current.handleSubmit(
                  e,
                  editedQuestion,
                  chatIndex
                );
                setEditIndex(null);
              }}
              className="mt-2 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded"
            >
              Save
            </button>
          </div>
        ) : (
          <pre
            onLoad={handleQuestionDisplayed}
            className="bg-gray-100 dark:bg-gray-800 rounded-b-md font-sans text-[16px] leading-[1.5] text-gray-800 dark:text-gray-200 whitespace-pre-wrap p-3"
            style={{ fontFamily: '"Inter", sans-serif' }}
          >
            {chat.question || chat.content}
          </pre>
        )}
        {chat.files && chat.files.length > 0 && (
          <div>
            <h4 className="font-bold mt-2 text-[18px] leading-[1.5] text-gray-800 dark:text-gray-200">
              Attached Files:
            </h4>
            <ul className="font-sans text-[16px] leading-[1.5] text-gray-800 dark:text-gray-200">
              {chat.files.map((file, fileIndex) => (
                <li key={fileIndex}>
                  {getFileIcon(file.type)} {file.name}
                </li>
              ))}
            </ul>
          </div>
        )}
        <h3 className="font-bold mt-5 text-[18px] leading-[1.5] text-gray-800 dark:text-gray-200">
          Answer:
        </h3>
        <CodeBlockHandler answer={chat.answer} />
        {/* <TypingTextWrapper answer={chat.answer} /> */}
      </div>
    ))}
  </div>
);

// const TypingTextWrapper = ({ answer }) => {
//   const [fullText, setFullText] = useState("");
//   const [currentText, setCurrentText] = useState("");
//   const [isTyping, setIsTyping] = useState(true);

//   useEffect(() => {
//     setFullText(answer);
//     setCurrentText("");
//     setIsTyping(true);
//   }, [answer]);

//   const handleComplete = () => {
//     setIsTyping(false);
//   };

//   return (
//     <pre className="bg-gray-100 dark:bg-gray-800 rounded-b-md font-sans text-[16px] leading-[1.5] text-gray-800 dark:text-gray-200 whitespace-pre-wrap p-3">
//       {isTyping ? (
//         <TypingText text={fullText} speed={30} onComplete={handleComplete} />
//       ) : (
//         <CodeBlockHandler answer={fullText} />
//       )}
//     </pre>
//   );
// };

export default ChatHistoryDisplay;
