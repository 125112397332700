import { useCallback } from "react";

const useFetchWithAuth = (serverUrl) => {
  return useCallback(
    async (url, options = {}) => {
      let accessToken = localStorage.getItem("accessToken");
      const response = await fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${accessToken}`,
        },
        credentials: "include",
      });
      if (response.status === 401 || response.status === 403) {
        const refreshResponse = await fetch(`${serverUrl}/api/refresh-token`, {
          method: "POST",
          credentials: "include",
        });
        if (refreshResponse.ok) {
          const { accessToken: newAccessToken } = await refreshResponse.json();
          localStorage.setItem("accessToken", newAccessToken);
          const retryResponse = await fetch(url, {
            ...options,
            headers: {
              ...options.headers,
              Authorization: `Bearer ${newAccessToken}`,
            },
            credentials: "include",
          });
          return retryResponse;
        } else {
          window.location.href = "/login";
          throw new Error("Authentication failed");
        }
      }
      return response;
    },
    [serverUrl]
  );
};

export default useFetchWithAuth;
