import React from "react";
import Header from "./Landing/Header";
import Hero from "./Landing/Hero";
import Features from "./Landing/Features";
import Benefits from "./Landing/Benefits";
import Comparison from "./Landing/Comparison";
import CTABanner from "./Landing/CTABanner";
import Footer from "./Landing/Footer";

function Landing() {
  return (
    <div className="bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100">
      <Header />
      <Hero />
      <Features />
      <Benefits />
      <Comparison />
      <CTABanner />
      <Footer />
    </div>
  );
}

export default Landing;
