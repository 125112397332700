import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${serverUrl}/api/database`);
        setUser(response.data.user);
      } catch (error) {
        if (error.response.status === 401) {
          navigate("/login");
        } else {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUser();
  }, [serverUrl, navigate]);
  console.log("user: injaaaas   s",user);
  return user ? (
    <div>
      
      <h1>Welcome, {user.email}</h1>
      {/* Add more user-specific content here */}
    </div>
  ) : (
    <p>Loading...</p>
  );
}

export default Dashboard;
