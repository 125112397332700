// Icons.js
export const CopyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 448 512"
    fill="gray"
  >
    <path d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z" />
  </svg>
);

export const DoneIcon = () => (
  <svg
    fill="green"
    width="24"
    height="24"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 26.016v-20q0-2.496 1.76-4.256t4.256-1.76h20q2.464 0 4.224 1.76t1.76 4.256v20q0 2.496-1.76 4.224t-4.224 1.76h-20q-2.496 0-4.256-1.76t-1.76-4.224zM4 26.016q0 0.832 0.576 1.408t1.44 0.576h20q0.8 0 1.408-0.576t0.576-1.408v-20q0-0.832-0.576-1.408t-1.408-0.608h-20q-0.832 0-1.44 0.608t-0.576 1.408v20zM7.584 16q0-0.832 0.608-1.408t1.408-0.576 1.408 0.576l2.848 2.816 7.072-7.040q0.576-0.608 1.408-0.608t1.408 0.608 0.608 1.408-0.608 1.408l-8.48 8.48q-0.576 0.608-1.408 0.608t-1.408-0.608l-4.256-4.256q-0.608-0.576-0.608-1.408z"></path>
  </svg>
);

export const paperClipIcon = (
  <svg
    width="28"
    height="28"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 949.801 949.801"
    fill="lightgray"
  >
    <g>
      <path
        d="M474.9,80c38.2,0,74.2,14.9,101.3,42c27.101,27.1,42.2,63.2,42.3,101.6l0.101,354.701c0,22,17.899,39.898,39.899,39.898
  h0.7c22,0,39.9-18,39.9-39.898l-0.3-354.701c0-30.2-5.9-59.5-17.601-87c-11.3-26.6-27.399-50.5-47.899-71.1
  c-20.5-20.5-44.4-36.6-71-47.9C534.801,5.9,505.5,0,475.4,0h-0.6c-30.301,0-59.601,5.9-87.2,17.6c-26.7,11.3-50.601,27.5-71.2,48
  c-20.5,20.6-36.7,44.5-48,71.1c-11.7,27.6-17.6,56.8-17.7,87l0.5,549.3c-0.1,47.4,18.2,92.1,51.8,125.699
  c32.9,33,75.801,51.102,120.9,51.102c45.2,0,88.2-18.201,121.2-51.102c33.6-33.699,52.1-78.398,52.1-126V452.2
  c0-35.8-11.899-67.8-33.399-92c-48.601-54.8-128-49.7-174.5-3.2c-24.9,24.9-36.601,60.1-36.601,95.4l0.3,130.1
  c0,22,17.9,39.9,39.9,39.9h0.8c22,0,39.9-17.9,39.9-39.9v-1.1c0-10.801-0.101-128.201-0.101-129.1c0-62.3,83-63.3,83,0
  l0.101,320.399v4.801c0,22-9.5,44.699-26,62.1C474.101,857.1,451.8,867.9,429.5,869.4c-1.8,0.1-3.5,0.199-5.3,0.199
  c-23.2,0-45.7-10.1-63.4-28.4c-17.1-17.799-27.5-41.299-28.3-64.5v-4.398L331.601,223.4v-4.1C332.7,182,348,147,374.9,120.7
  C401.9,94.7,437.4,80.1,474.9,80z"
      />
    </g>
  </svg>
);

export const SubmitIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className="text-white dark:text-black"
  >
    <path
      d="M7 11L12 6L17 11M12 18V7"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);

export const CloseIcon = () => (
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15 6L9 12L15 18" stroke="#D3D3D3" strokeWidth="4" />
  </svg>
);
export const OpenIcon = () => (
  <svg
    width="30px"
    height="30px"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path d="M9 6L15 12L9 18" stroke="#D3D3D3" strokeWidth="4" />
  </svg>
);

export const RemoveIcon = () => (
  <svg
    version="1.1"
    id="svg2"
    xmlns="http://www.w3.org/2000/svg"
    width="30px"
    height="30px"
    viewBox="0 0 24 24"
    enable-background="new 0 0 24 24"
  >
    <path
      id="path18520"
      d="M600,0C268.63,0,0,268.63,0,600c0,331.369,268.63,600,600,600
	c331.369,0,600-268.63,600-600S931.369,0,600,0z M600,130.371c259.369,0,469.556,210.325,469.556,469.629
	c0,259.305-210.187,469.556-469.556,469.556c-259.37,0-469.556-210.251-469.556-469.556C130.445,340.696,340.63,130.371,600,130.371
	L600,130.371z M435.425,305.347L305.347,435.425L469.922,600L305.347,764.575l130.078,130.078L600,730.078l164.575,164.575
	l130.078-130.078L730.078,600l164.575-164.575L764.575,305.347L600,469.922L435.425,305.347z"
    />
  </svg>
);
