import React from "react";
import { useTheme } from "./ThemeContext";
import { useNavigate } from "react-router-dom";
import { BsHouse } from "react-icons/bs";

function Terms() {
  const { theme } = useTheme();
  const navigate = useNavigate();

  return (
    <div
      className={`flex flex-col h-screen overflow-hidden bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 ${theme}`}
    >
      <div
        className={`flex flex-col h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 ${theme}`}
      >
        <button
          onClick={() => navigate("/mainPage")}
          className="h-14 bg-blue-100 dark:bg-gray-800 text-gray-900 dark:text-gray-100 border-b border-gray-200 dark:border-gray-700 flex items-center px-4 z-20"
        >
          <BsHouse size={20} />
        </button>

        <div className="overflow-y-auto flex-grow">
          <div className="flex justify-center items-center h-screen bg-blue-100 dark:bg-gray-800 p-4">
            <div className="w-full max-w-4xl h-[100vh] bg-white dark:bg-gray-900 shadow-md overflow-hidden flex flex-col">
              <div className="p-8 overflow-y-auto flex-grow">
                <ul className="list-none mb-6 text-gray-800 dark:text-gray-200">
                  <li className="mb-6">
                    <h1 className="text-2xl font-bold mb-3 text-gray-800 dark:text-gray-200">
                      Responxe.com Terms of Service
                    </h1>
                    <h2 className="text-l font-bold text-gray-800 dark:text-gray-200 mt-4">
                      Last Updated: October 13, 2024
                    </h2>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      1. Acceptance of Terms
                    </h2>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-2">
                      Welcome to Responxe.com ("we", "our", "us"). By accessing
                      or using our website located at responxe.com, you agree to
                      comply with and be bound by these Terms of Service
                      ("Terms"). Please read them carefully.
                    </p>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      2. Services Provided
                    </h2>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-2">
                      Responxe.com offers access to a collection of top Gen AI
                      models that users can interact with to ask questions and
                      receive answers.
                    </p>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      3. Subscription and Payment
                    </h2>
                    <ul className="list-disc list-inside mt-2 ml-6 text-gray-800 dark:text-gray-300">
                      <li>
                        <strong>Pricing:</strong> Access to our Service is
                        available for a monthly subscription fee. For
                        information about our current pricing, please visit our{" "}
                        <a
                          href="/myplan"
                          className="text-blue-500 hover:underline"
                        >
                          Pricing Page
                        </a>
                        .
                      </li>
                      <li>
                        <strong>Payment:</strong> Payments are processed
                        securely through [Payment Processor]. You agree to
                        provide accurate billing information.
                      </li>
                      <li>
                        <strong>Cancellation:</strong> You may cancel your
                        subscription at any time. Upon cancellation, access will
                        continue until the end of the current billing cycle.
                        After cancellation, you will not be charged for any
                        future billing periods.
                      </li>
                    </ul>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      4. User Responsibilities
                    </h2>
                    <ul className="list-disc list-inside mt-2 ml-6 text-gray-800 dark:text-gray-300">
                      <li>
                        <strong>Account Security:</strong> You are responsible
                        for maintaining the confidentiality of your account
                        credentials.
                      </li>
                      <li>
                        <strong>Acceptable Use:</strong> You agree not to misuse
                        the Service, including but not limited to engaging in
                        unlawful activities, disrupting the Service, or
                        violating intellectual property rights.
                      </li>
                    </ul>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      5. Intellectual Property
                    </h2>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-2">
                      All content and materials on the Service are owned by or
                      licensed to us. You may not reproduce, distribute, or
                      create derivative works without our explicit permission.
                    </p>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      6. Limitation of Liability
                    </h2>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-2">
                      We strive to provide reliable Service but do not guarantee
                      uninterrupted access. We are not liable for any indirect,
                      incidental, or consequential damages arising from your use
                      of the Service.
                    </p>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      7. Termination
                    </h2>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-2">
                      We reserve the right to terminate or suspend your access
                      to the Service at our discretion, without notice, for
                      conduct that violates these Terms or is harmful to other
                      users.
                    </p>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      8. Governing Law
                    </h2>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-2">
                      These Terms of Service are governed by the laws of [Your
                      Jurisdiction]. Any disputes will be resolved in the courts
                      of [Your Jurisdiction].
                    </p>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      9. Changes to Terms
                    </h2>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-2">
                      We may update these Terms of Service from time to time.
                      Continued use of the Service constitutes acceptance of the
                      revised terms.
                    </p>
                  </li>
                  <li className="mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                      10. Contact Us
                    </h2>
                    <p className="text-l text-gray-800 dark:text-gray-300 mt-2">
                      If you have any questions about these Terms, please
                      contact us at:
                    </p>
                    <ul className="list-disc list-inside mt-2 ml-6">
                      <li>Email: gpthubai2024@gmail.com</li>
                      {/* <li>[Any other contact methods you provide]</li> */}
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms;
