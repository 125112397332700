import React from "react";

function Hero() {
  return (
    <section className="bg-gray-100 dark:bg-gray-700 py-20">
      <div className="container mx-auto text-center px-4">
        <h1 className="text-4xl font-bold mb-4">
          Access the World's Top AI Models Effortlessly
        </h1>
        <p className="text-xl mb-8">
          Unlock unlimited AI interactions without limitations, all for as low
          as $10.
        </p>
        <a
          href="/signup"
          className="bg-indigo-600 text-white px-6 py-3 rounded-md hover:bg-indigo-700"
        >
          Get Started Now
        </a>
      </div>
    </section>
  );
}
export default Hero;
