import React from "react";
import { IoAlertCircleSharp } from "react-icons/io5";

const UsageWarningModal = ({ setShowUsageWarning, onUpgrade }) => {
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white dark:bg-gray-800 text-gray-800 dark:text-white p-6 rounded-lg shadow-xl max-w-md w-full relative">
        <button
          onClick={() => setShowUsageWarning(false)}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          aria-label="Close"
        >
          <IoAlertCircleSharp size={24} />
        </button>
        <h2 className="text-xl font-bold text-yellow-500 mb-4">
          Usage Warning
        </h2>
        <p className="mb-4 text-gray-600 dark:text-gray-300">
          You have reached 90% of your usage cap. Consider upgrading your plan
          to avoid interruptions.
        </p>
        <div className="flex justify-end">
          <button
            onClick={onUpgrade}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
          >
            Upgrade Plan
          </button>
        </div>
      </div>
    </div>
  );
};

export default UsageWarningModal;
