import React from "react";
import { IoCloseCircleSharp } from "react-icons/io5";

const UsageLimitExceededModal = ({ setShowUsageLimitModal, onUpgrade }) => {
  return (
    // <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-sm w-full relative">
    <div className="bg-white dark:bg-gray-800 text-gray-800 dark:text-white p-6 rounded-lg shadow-xl max-w-md w-full relative">
      <button
        onClick={() => setShowUsageLimitModal(false)}
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        aria-label="Close"
      >
        <IoCloseCircleSharp size={24} />
      </button>
      <div className="flex justify-between items-start mb-4">
        <h2 className="text-xl font-bold text-red-500">Usage Limit Exceeded</h2>
      </div>
      <p className="mb-4 text-gray-600 dark:text-gray-300">
        You have reached your usage limit. Please upgrade your plan to continue
        using our services.
      </p>
      <div className="flex justify-end">
        <button
          onClick={onUpgrade}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
        >
          Upgrade Plan
        </button>
      </div>
    </div>
  );
};

export default UsageLimitExceededModal;
