import React from "react";
import {
  FaFile,
  FaFileWord,
  FaFilePdf,
  FaFileExcel,
  FaFilePowerpoint,
  FaFileCode,
  FaFileAlt,
  FaFileImage,
} from "react-icons/fa";

const getFileIcon = (fileType) => {
  switch (fileType.toLowerCase()) {
    case "doc":
    case "docx":
      return <FaFileWord className="text-blue-500" />;
    case "pdf":
      return <FaFilePdf className="text-red-500" />;
    case "xls":
    case "xlsx":
      return <FaFileExcel className="text-green-500" />;
    case "ppt":
    case "pptx":
      return <FaFilePowerpoint className="text-orange-500" />;
    case "py":
    case "js":
    case "java":
    case "cpp":
    case "html":
    case "css":
      return <FaFileCode className="text-yellow-500" />;
    case "txt":
      return <FaFileAlt className="text-gray-500" />;
    case "png":
    case "jpg":
    case "jpeg":
    case "gif":
      return <FaFileImage className="text-purple-500" />;
    default:
      return <FaFile className="text-gray-500" />;
  }
};

const FilePreview = ({ file }) => {
  const fileExtension = file.name.split(".").pop().toLowerCase();
  const isImage = ["png", "jpg", "jpeg", "gif"].includes(fileExtension);
  // const isImage = file.type.startsWith("image/");

  return (
    <div className="w-22 h-16 flex flex-col items-center justify-center text-gray-500 rounded-md shadow-sm overflow-hidden bg-white p-2">
      {isImage ? (
        <div className="w-full h-full flex items-center justify-center">
          <img
            src={URL.createObjectURL(file)}
            alt={file.name}
            className="w-full h-full object-cover"
          />
        </div>
      ) : (
        //   <div className="flex flex-col items-center">
        //     {getFileIcon(fileExtension)}
        //     <span className="text-xs mt-1">{fileExtension.toUpperCase()}</span>
        //   </div>
        // )}
        <>
          {getFileIcon(fileExtension)}
          <span className="text-xs mt-1 truncate w-full text-center">
            {fileExtension.toUpperCase()}
          </span>
        </>
      )}
    </div>
  );
};

export default FilePreview;
